import React, {Component} from 'react';
import {PropTypes, cn} from '../../util.js';

export class Icon extends Component {
  static propTypes = {
    fa: PropTypes.string.isRequired,
    className: PropTypes.string,
  }

  render() {
    const {fa, className, ...props} = this.props;
    return (
      <i
        className={cn('fa', fa, className, {clickable: !!props.onClick})}
        {...props}
      />
    );
  }
}
