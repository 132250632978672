import {store} from './store.js';

function buildUrl(url) {
  const headers = new Headers({
    'Access-Control-Allow-Origin':'*',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  });

  const fetchConfig = {method: 'GET', headers: headers, mode: 'cors', cache: 'default'};
  const req = new Request(url, fetchConfig);
  return req;
}

function catchHandler(err) {
  console.log('oepsie', err); // eslint-disable-line
}


const httpGet = url => {
  return fetch(buildUrl(url)).then(res => {
    if (res.ok) {
      return res.json();
    }
    catchHandler(res);
    return [];
  }, catchHandler);
};


const httpPost = url => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin':'*',
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    //body: JSON.stringify({})
  });
};


export const httpClient = {
  get: httpGet,
  post: httpPost,
};
