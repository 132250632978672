import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from '../../util.js';
import {FacebookProvider, EmbeddedPost} from 'react-facebook';

export const FacebookPost = connect(state => ({
  facebookAppId: state.config.socialMedia.facebookAppId,
  facebookPostLink: state.config.socialMedia.facebookPostLink,
  t: state.t,
}))(class extends Component {
  static propTypes = {
    facebookAppId: PropTypes.string.isRequired,
    facebookPostLink: PropTypes.string.isRequired,
    t: PropTypes.any.isRequired,
  }

  render() {
    const post = this.props.facebookPostLink;
    return (
      <FacebookProvider appId={this.props.facebookAppId} language={this.props.t('facebookLanguage')}>
        <EmbeddedPost href={post} showText={false} width="330" />
      </FacebookProvider>
    );
  }
});
