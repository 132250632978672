import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from '../../../util.js';

class CookieNoticeComponent extends Component {
  static propTypes = {
    t: PropTypes.any.isRequired,
  }

  render() {
    const {t} = this.props;
    if (localStorage.getItem('cookieNoticeKilled')) {
      return null;
    }

    return (
      <div className="cookie-notice">
        {t('footer.cookieNotice')}
        <u
          className="clickable"
          style={{marginLeft: 8}}
          onClick={() => this._killCookieNotice()}
        >
          {t('footer.cookieNoticeClose')}
        </u>
      </div>
    );
  }

  _killCookieNotice() {
    localStorage.setItem('cookieNoticeKilled', true);
    this.forceUpdate();
  }
}

export const CookieNotice = connect(state => ({t: state.t}))(CookieNoticeComponent);
