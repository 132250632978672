import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from '../../../util.js';
import {Icon} from '../../controls.js';

const mapStateToProps = state => ({telephoneLink: state.config.contact.telephoneLink, t: state.t});
export const CallUsIcon = connect(mapStateToProps)(class extends Component {
  static propTypes = {
    telephoneLink: PropTypes.string.isRequired,
    t: PropTypes.any.isRequired,
  }
  render() {
    return (
      <a href={'tel:' + this.props.telephoneLink} className="nav-link" title={this.props.t('nav.callUs')}>
        <Icon fa="fa-fw fa-phone-square" />
      </a>
    );
  }
});
