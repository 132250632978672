// @flow
import {Translation} from './TranslationModel.js';

export const MainCategories = [
  'ACCESORIES',
  'AIRSOFT',
  'CAMPING',
  'TOOLS',
  'CLOTHING',
  'EQUIPMENT',
];

export class Category {
  id: string;
  mainCategory: string;
  mainCategoryImg: string;

  _name: Translation;
  _categories: Array<string>;

  categories: Array<Category>;
  specs: Array<Spec>;
  options: Array<SpecOption>;

  get slug(): string {
    return '/category/' + this._name.slug() + '/' + this.id;
  }

  get name(): string {
    return this._name.get();
  }

  constructor(json: any) {
    this.id = json.id;
    this._name = new Translation(json.name);
    this.mainCategory = json.mainCategory;
    this.mainCategoryImg = json.mainCategoryImg;

    this._categories = json.categories || [];
    this.categories = []; // mapped after INITIAL_LOAD
    this.specs = json.specs ? json.specs.map(x => new Spec(x)) : [];
    this.options = json.options;
  }

  setSubCategories(cats: Array<Category>) {
    this.categories = cats.filter(c => !!c);
  }
}

export class Spec {
  id: string;
  name: Translation;
  specOptions: Array<SpecOption>;
  type: string; // oneOf: SHOES-EUR

  constructor(json: any) {
    this.id = json.id;
    this.name = new Translation(json.name);
    this.specOptions = json.specOptions ? json.specOptions.map(x => new SpecOption(x)) : [];
    this.type = json.type;
  }
}

export class SpecOption {
  id: string;
  name: Translation;
  title: Translation;

  constructor(json: any) {
    this.id = json.id;
    this.name = new Translation(json.name);
    this.title = new Translation(json.title);
  }
}
