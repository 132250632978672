import React, {Component} from 'react';

// TODO: Untranslated "Google maps" title
// TODO: APi key should come from configReducer
// TODO: show map in current language: &language=nl&region=BE

import './GoogleMaps.css';

export class GoogleMaps extends Component {
  render() {
    // Version with street plan
    // return (
    //   <div className={'google-maps '} {...this.props} >
    //     <iframe
    //       src="" // eslint-disable-line
    //       frameBorder="0"
    //       style={{border: 0}}
    //       allowFullScreen
    //       title="Google maps"
    //     />
    //   </div>
    // );

    // Version with sattelite view
    return (
      <div className="google-maps" {...this.props} >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2926.365605876013!2d4.238157151408073!3d50.8956067629692!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3c0607118daad%3A0x642b0cf3a207a6e3!2sAmerikaanse+Stock+%2F+Stock+am%C3%A9ricain+Stockoma!5e1!3m2!1sen!2sbe!4v1521411932575&key=3AIzaSyDirSN9ugBQxCzlTZs_G-kyMIbAytRKqQY" // eslint-disable-line
          frameBorder="0"
          style={{border: 0}}
          allowFullScreen
          title="Google maps"
        />
      </div>
    );
  }
}
