import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from '../../../util.js';
import {Link} from 'react-router-dom';
import {Icon} from '../../controls.js';

// TODO: icon? fa-id-card / fa-question-circle / fa-comments-o

export const HeaderContactIcon = connect(state => ({t: state.t}))(class extends Component {
  static propTypes = {
    t: PropTypes.any.isRequired,
  }
  render() {
    return (
      <Link to="/contact" className="nav-link" title={this.props.t('nav.contactUs')}>
        <Icon fa="fa-fw fa-question-circle" />
      </Link>
    );
  }
});
