import React, {Component} from 'react';
import {PropTypes} from '../../../util.js';
import {connect} from 'react-redux';
import {ProductList} from '../../product/ProductList.jsx';
import {Pagination} from '../../controls.js';

class CategoryPageProductListComponent extends Component {
  static propTypes = {
    products: PropTypes.ProductModelList.isRequired,
    catConfig: PropTypes.shape({
      pageSize: PropTypes.number.isRequired,
      sortBy: PropTypes.string.isRequired,
    }).isRequired,
    category: PropTypes.CategoryModel.isRequired,
    page: PropTypes.number.isRequired,
  }

  render() {
    const {category, catConfig, page} = this.props;

    var products = this.props.products.filter(prod => prod.categories.includes(category.id));
    if (category.mainCategory) {
      const subCatIds = category.categories.map(cat => cat.id);
      products = products.concat(this.props.products.filter(prod => prod.categories.some(catId => subCatIds.includes(catId))));
    }

    const sortBy = catConfig.sortBy.split('-')[0];
    const sortDirection = catConfig.sortBy.split('-')[1];
    if (sortBy === 'name') {
      products = products.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortBy === 'price') {
      products = products.sort((a, b) => a.price - b.price);
    } else if (sortBy === 'popularity') {
      products = products.sort((a, b) => a.views - b.views);
    }

    if (sortDirection === 'desc') {
      products = products.reverse();
    }

    const pageCount = Math.ceil(products.length / catConfig.pageSize);
    const currentPage = page > pageCount ? 1 : page;
    const sliceStart = (currentPage - 1) * catConfig.pageSize;

    products = products.slice(sliceStart, sliceStart + catConfig.pageSize);

    return (
      <div>
        <ProductList products={products} />
        <div className="d-flex">
          <Pagination pageCount={pageCount} currentPage={currentPage} baseUrl={category.slug} />
        </div>
      </div>
    );
  }
}

const categePageStateMapper = state => ({products: state.prods, catConfig: state.app.category});
export const CategoryPageProductList = connect(categePageStateMapper)(CategoryPageProductListComponent);
