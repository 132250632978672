import React from 'react';
import PropTypes from 'prop-types';

export const PrivacyStatementNl = ({gdprEmail, registeredOffice}) => (
  <div>
    <h1>Privacyverklaring</h1>

    <h2>Samenvatting van de privacyverklaring</h2>

    <p>In deze privacyverklaring wordt uitgelegd welke informatie wij over u verzamelen, waarvoor
    wij deze informatie gebruiken en aan wie wij deze informatie geven. Ook wordt uiteengezet
    wat uw rechten zijn met betrekking tot uw gegevens en met wie u contact kunt opnemen als u
    vragen hebt of meer informatie wenst.</p>

    <p>Uw informatie wordt gebruikt in overeenstemming met de toestemmingen die u verstrekt
    hebt, met de wet van 30 juli 2018 betreffende de bescherming van natuurlijke personen met
    betrekking tot de verwerking van persoonsgegevens en de Europese verordening 2016/679
    van het Europees Parlement en de Raad van 27 april 2016 (in werking getreden op 25 mei
    2018) betreffende de bescherming van natuurlijke personen in verband met de verwerking van
    persoonsgegevens en betreffende het vrije verkeer van die gegevens (AVG).</p>

    <h2>Waar gaat deze privacyverklaring over?</h2>

    <p>In deze privacyverklaring wordt uiteengezet hoe wij informatie over u zullen verzamelen,
    opslaan, beschermen en ermee omgaan wanneer:</p>

    <ul>
      <li>wij diensten aanbieden aan u of uw klanten</li>
      <li>u “onze website” gebruikt of</li>
      <li>andere activiteiten uitvoert die deel uitmaken van onze bedrijfsuitoefening, zoals
          hieronder in detail beschreven.</li>
    </ul>

    <p>Wanneer wij in dit beleid verwijzen naar “onze website” of “deze website”, bedoelen wij de
    specifieke webpagina van Stockoma.be.</p>

    <p>Deze privacyverklaring bevat tevens informatie over de omstandigheden waarin wij uw
    persoonsgegevens delen met derden (bijvoorbeeld onze dienstverleners).</p>

    <p>In deze privacyverklaring worden uw gegevens soms aangeduid als “persoonsgegevens”,
    “gegevens” of “informatie”. Ook kunnen wij soms gezamenlijk verwijzen naar het omgaan
    met, verzamelen, beschermen en opslaan van uw persoonsgegevens als het “verwerken” van
    deze persoonsgegevens.</p>

    <h2>Welke informatie verzamelen wij?</h2>

    <p>Tijdens het aanbieden van diensten aan u of onze klanten en bij het uitvoeren van due
    diligence in verband met onze diensten (of het bespreken van mogelijke diensten die wij
    eventueel kunnen aanbieden) zullen wij persoonsgegevens van u verzamelen of verkrijgen.
    Ook kunnen wij persoonsgegevens van u verzamelen wanneer u onze website gebruikt.</p>

    <p>Wij kunnen deze gegevens verzamelen of verkrijgen omdat u deze aan ons geeft (bijvoorbeeld
    via een formulier op onze website, per mail of per telefoon), omdat anderen deze gegevens
    aan ons geven (bijvoorbeeld uw werkgever of adviseur, of externe dienstverleners waarvan
    wij gebruikmaken om ons bedrijf uit te oefenen) of omdat deze gegevens publiekelijk
    beschikbaar zijn.</p>

    <p>Wij kunnen ook persoonsgegevens van u verzamelen of verkrijgen omdat wij deze gegevens
    waarnemen of afleiden uit de manier waarop u op ons of anderen reageert. Een voorbeeld: om
    uw ervaring te verbeteren wanneer u onze website gebruikt, en om ervoor te zorgen dat deze
    goed functioneert, kunnen wij (of onze dienstverleners) gebruikmaken van cookies (kleine
    tekstbestandjes die worden opgeslagen in de browser van een gebruiker) en web beacons die
    persoonsgegevens kunnen verzamelen. Aanvullende informatie over hoe wij gebruikmaken
    van cookies en andere volgtechnologieën, en hoe u deze kunt controleren, is te vinden in ons
    Cookiebeleid .</p>

    <p>Tot de persoonsgegevens die wij verzamelen of verkrijgen, kunnen behoren: uw naam;
    leeftijd; geboortedatum; geslacht; e-mailadres; telefoonnummer; huisadres; land van verblijf;
    levensstijl en sociale omstandigheden (bijvoorbeeld uw hobby&#39;s); gezinssituatie (bijvoorbeeld
    uw burgerlijke staat en wie ten laste is van u); details over uw werk en opleiding
    (bijvoorbeeld de organisatie waar u werkt, uw functie en details over uw opleiding);
    financiële informatie en informatie gerelateerd aan belastingen (bijvoorbeeld uw inkomen en
    fiscale woonplaats); uw bijdragen op eventuele blogs, forums, wiki&#39;s en andere social media
    toepassingen en -diensten die wij aanbieden; uw IP-adres; uw browsertype en -taal; uw
    toegangstijden; details van klachten; details over hoe u uw producten en diensten gebruikt;
    details over hoe u graag met ons communiceert, en andere gelijksoortige informatie.</p>

    <p>De persoonsgegevens die wij verzamelen, kunnen ook zogenoemde ‘gevoelige’ of ‘bijzondere
    categorieën’ van persoonsgegevens omvatten, zoals details over uw: dieetwensen
    (bijvoorbeeld wanneer Stockoma u een lunch aanbiedt tijdens een vergadering), gezondheid
    (bijvoorbeeld zodat wij redelijke aanpassingen voor u kunnen doen in onze gebouwen,
    producten en diensten) en seksuele geaardheid (bijvoorbeeld indien u ons details over uw
    echtgeno(o)t(e) of partner verstrekt).</p>

    <p>De soorten en bijzondere categorieën van persoonsgegevens die wij verzamelen, kunnen
    variëren, afhankelijk van de aard van de diensten die wij u of onze klant aanbieden, of hoe u
    onze website gebruikt. In bepaalde ongewone omstandigheden zouden wij eventueel ook
    andere bijzondere categorieën van persoonsgegevens van u kunnen verzamelen omdat u deze
    gegevens vrijwillig aan ons aanbiedt of omdat wij deze gegevens moeten verzamelen wegens
    wettelijke vereisten die ons worden opgelegd.</p>

    <p>Wanneer wij van onze klant persoonsgegevens over u ontvangen, doen wij redelijke
    inspanningen om in de overeenkomst met de klant clausules op te nemen waardoor de klant
    de privacywet- en regelgeving moet naleven die voor deze gegevens geldt; Hieronder kan
    bijvoorbeeld vallen dat de klant u in kennis heeft gesteld van (onder andere) het verzamelen
    van gegevens en eventueel noodzakelijke toestemming heeft verkregen voor onze verwerking
    van deze gegevens zoals beschreven in deze privacyverklaring.</p>

    <p>Wij zijn ons bewust van het belang van de bescherming van de privacy van kinderen. Onze
    website en diensten zijn niet bedoeld voor of bewust gericht op kinderen. Het is niet ons
    beleid om bewust informatie over kinderen te verzamelen of op te slaan.</p>

    <h2>Hoe gebruiken wij informatie over u?</h2>

    <h3>Gebruik van persoonsgegevens voor dienstverlening aan onze klanten</h3>

    <p>Wij zullen uw persoonsgegevens gebruiken om u of onze klant de gevraagde diensten aan te
    bieden. Hierbij kunnen wij uw persoonsgegevens ook gebruiken in correspondentie met
    betrekking tot de diensten. Dergelijke correspondentie kan met u, onze klant, andere leden
    van het Stockoma-netwerk, onze dienstverleners of bevoegde autoriteiten zijn. Wij kunnen uw
    persoonsgegevens ook gebruiken om due diligence uit te voeren met betrekking tot de
    diensten. Omdat wij onze klanten uiteenlopende diensten aanbieden, verschilt de manier
    waarop persoonsgegevens gebruiken in verband met onze diensten. Wij kunnen bijvoorbeeld
    gebruikmaken van persoonsgegevens:</p>

    <ul>
      <li>de werknemers van een klant met het doel contact met
          hen op te nemen tijdens het uitvoeren van onze diensten om hen op de hoogte te
          houden van de voortgang van het project of met het doel te communiceren over de te
          leveren prestaties voor onze projecten.</li>
    </ul>

    <h3>Gebruik van persoonsgegevens voor andere activiteiten die deel uitmaken van onze bedrijfsuitoefening</h3>

    <p>Wij kunnen uw persoonsgegevens ook gebruiken met het oog op of in verband met:</p>
    <ul>
      <li>toepasselijke wetgevende vereisten</li>
      <li>verzoeken en communicaties van bevoegde autoriteiten</li>
      <li>administratieve doeleinden </li>
      <li>financiële administratie, facturering en risicoanalyse</li>
      <li>beheer van klantrelaties, waaronder: (i) inzichten en ideeën (thought leadership) of
          details over onze producten en diensten aan u sturen waarvan wij denken dat deze
          interessant voor u kunnen zijn; (ii) contact met u opnemen om feedback te ontvangen
          over diensten; en (iii) contact met u opnemen met het oog op andere markt- of
          onderzoeksdoeleinden. In alle bovenstaande gevallen stellen wij u in de gelegenheid
          om onze aanbiedingen en verzoeken op elk gewenst moment van de hand te wijzen.
          Indien nodig zullen wij in specifieke gevallen, bijvoorbeeld in het geval u nog geen
          klant bent, ervoor zorgen uw voorafgaande toestemming te verkrijgen voordat wij u
          communicatiematerialen of andere marketingverzoeken versturen (zie ook &quot;De
          rechtsgronden waarop wij uw persoonsgegevens verwerken” verderop); </li>
      <li>wervings- en bedrijfsontwikkelingsdoeleinden. Zo kan bijvoorbeeld gebruik worden
          gemaakt van testimonials van de werknemers van een klant in onze wervings- en
          bedrijfsontwikkelingsmaterialen, met toestemming van de desbetreffende werknemers;</li>
      <li>diensten die wij ontvangen van onze professionele adviseurs, zoals advocaten,
          accountants en consultants</li>
      <li>bescherming van onze rechten en die van onze klanten.</li>
    </ul>

    <h3>Gebruik van persoonsgegevens verzameld via onze website</h3>
    <p>Naast de hierboven vermelde doeleinden in verband met de uitoefening van onze diensten
    kunnen wij ook gebruikmaken van persoonsgegevens van u die zijn verzameld via onze
    website voor de volgende doeleinden:</p>
    <ul>
      <li>het beheren en verbeteren van onze website</li>
      <li>het afstemmen van de inhoud van de website teneinde u een meer op maat gesneden
          ervaring te bieden en uw aandacht te vestigen op informatie over onze producten en
          diensten die voor u interessant kunnen zijn</li>
      <li>het beheren en reageren op eventuele verzoeken die u via onze website indient.</li>
    </ul>

    <h2>De rechtsgronden waarop wij uw persoonsgegevens verwerken</h2>
    <p>Wij zijn wettelijk verplicht om in deze privacyverklaring de rechtsgronden te vermelden op
    basis waarvan wij uw persoonsgegevens verwerken.<br />
    Wij gebruiken uw persoonsgegevens voor de hierboven beschreven doeleinden vanwege: (a)
    de in wet- en regelgeving neergelegde verplichtingen die wij moeten nakomen, zoals het
    bijhouden van een administratie met het oog op regelgevings- of belastingdoeleinden of het
    verstrekken van informatie aan een overheidsorgaan of wetshandhavingsinstantie of (b) het
    vereist zijn van de gegevens voor levering van onze diensten aan u of de klant. (c) onze
    gerechtvaardigde belangen in de adequate levering van onze diensten aan u of onze klant; (d)
    onze gerechtvaardigde belangen in de adequate en rechtmatige uitoefening van ons bedrijf,
    mits uw belangen niet zwaarder wegen dan deze belangen;</p>

    <p>Voor zover wij gevoelige persoonsgegevens over u verwerken met het oog op de hierboven
    beschreven doeleinden, zullen wij dit doen omdat: (i) hetzij u ons uitdrukkelijk toestemming
    hebt gegeven voor verwerking van deze gegevens; (ii) hetzij wij wettelijk verplicht zijn om
    deze gegevens te verwerken om ervoor te zorgen dat wij onze ‘ken uw klant’- en
    ‘antiwitwas’-verplichtingen nakomen (of andere wettelijke verplichtingen die ons worden
    opgelegd); (iii) hetzij de verwerking noodzakelijk is voor het nakomen van onze wettelijke
    verplichtingen, in het bijzonder arbeidsrechtelijke en sociale zekerheidsrechtelijke
    verplichtingen;(iv) hetzij omdat de verwerking noodzakelijk is voor het instellen van
    rechtsvorderingen, de uitvoering ervan of de verdediging ertegen; (v) hetzij omdat u de
    gegevens openbaar hebt gemaakt.</p>

    <p>Wanneer wij wettelijk verplicht zijn om uw uitdrukkelijke toestemming te verkrijgen om
    bepaalde marketingmaterialen aan u te verstrekken, zullen wij deze marketingmaterialen
    uitsluitend aan u verstrekken indien wij deze toestemming van u hebben verkregen.</p>

    <h2>Aan wie verstrekken wij uw gegevens?</h2>
    <p>In verband met een of meer van de doeleinden zoals beschreven in het gedeelte “Hoe
    gebruiken wij informatie over u?” hierboven kunnen wij gegevens over u meedelen aan:
    derden die diensten aan ons leveren; bevoegde autoriteiten (waaronder rechtbanken en
    autoriteiten die ons regels opleggen); uw werkgever en/of diens adviseurs; uw adviseurs;
    organisaties die ons helpen om het aantal fraudegevallen te beperken, en andere derden die
    redelijkerwijs toegang eisen tot persoonsgegevens van u met het oog op een of meer van de
    doeleinden zoals beschreven in het gedeelte “Hoe gebruiken wij informatie over u?”
    hierboven.</p>

    <p>Onze website biedt plaats aan diverse blogs, forums, wiki&#39;s en andere socialemedia -
    toepassingen of -diensten die het mogelijk maken dat u content deelt met andere gebruikers
    (gezamenlijk “social media”). Van belang is dat alle persoonsgegevens die u aan deze social
    media bijdraagt, kunnen worden gelezen, verzameld en gebruikt door andere gebruikers van
    de toepassing. Wij hebben weinig tot geen controle over deze andere gebruikers en kunnen
    daarom niet garanderen dat er met informatie die u aan social media bijdraagt, wordt
    omgegaan overeenkomstig deze privacyverklaring.</p>

    <p>Merk op dat sommige ontvangers van bovengenoemde persoonsgegevens gevestigd kunnen
    zijn in landen buiten de Europese Unie waar de wetgeving mogelijk niet hetzelfde
    gegevensbeschermingsniveau biedt. In dergelijke gevallen zullen wij ervoor zorgen dat er
    afdoende maatregelen worden getroffen om uw persoonsgegevens te beschermen zodat wij
    aan onze wettelijke verplichtingen voldoen. De afdoende maatregel bestaan uit een
    gegevensoverdrachtovereenkomst met de ontvanger op basis van standaardcontractbepalingen
    zoals goedgekeurd door de Europese Unie voor de overdracht van persoonsgegevens aan
    derde landen.</p>

    <p>Het kan ook nodig zijn dat wij uw persoonsgegevens doorgeven, indien dit ons wordt
    opgelegd door wetgeving, een toezichthouder of tijdens een gerechtelijke procedure.</p>

    <p>Wij kunnen niet-persoonlijke, geanonimiseerde en geaggregeerde gegevens doorgeven aan
    derden met het oog op verscheidene doeleinden, waaronder data-analyse, onderzoek,
    aanvragen, &#39;thought leadership’ en promotionele doeleinden.</p>

    <h2>Bescherming van uw persoonsgegevens</h2>
    <p>Wij maken gebruik van uiteenlopende fysieke, elektronische en beheersmaatregelen om
    ervoor te zogen dat wij uw persoonsgegevens veilig, correct en actueel houden. Onder deze
    maatregelen vallen:</p>

    <ul>
      <li>voorlichting en training aan de betreffende medewerkers om ervoor te zorgen dat ze
          zich bewust zijn van onze privacyverplichtingen wanneer zij met persoonsgegevens
          omgaan</li>
      <li>administratieve en technische controles om inzage van persoonsgegevens te beperken
          tot degenen voor wie hiertoe een noodzaak bestaat</li>
      <li>technologische beveiligingsmaatregelen, waaronder firewall, versleuteling en
          antivirussoftware</li>
      <li>fysieke beveiligingsmaatregelen, zoals beveiligingspassen voor toegang tot onze
          terreinen. </li>
    </ul>

    <p>Hoewel wij vanaf het moment van ontvangen van uw persoonsgegevens gebruikmaken van
    passende beveiligingsmaatregelen, is de verzending van gegevens via internet (met inbegrip
    van e-mail) nooit helemaal veilig. Wij streven ernaar persoonsgegevens te beschermen, maar
    kunnen de veiligheid van naar of door ons verzonden gegevens niet garanderen.</p>

    <h2>Hoelang bewaren wij uw gegevens?</h2>

    <p>Wij zullen uw persoonsgegevens in onze systemen bewaren gedurende de onderstaande
    periode die het langst is: (i) zo lang als nodig is voor de uitoefening van desbetreffende
    activiteit of diensten (ii) een wettelijk verplichte bewaarperiode; of (iii) het einde van de
    periode waarin een civiele procedure kan worden aangespannen of onderzoeken kunnen
    worden ingesteld met betrekking tot de geleverde diensten.</p>

    <h3>Uw rechten</h3>
    <p>U hebt diverse rechten met betrekking tot uw persoonsgegevens. U hebt met name het recht
    om:</p>

    <ul>
      <li>bevestiging te krijgen dat wij uw persoonsgegevens verwerken, en te verzoeken om
          een kopie van de persoonsgegevens die wij van u hebben te ontvangen.</li>
      <li>ons te verzoeken om de persoonsgegevens die wij van u hebben, bij te werken of om
          persoonsgegevens die volgens u onjuist of onvolledig zijn, te corrigeren</li>
      <li>ons te verzoeken om persoonsgegevens die wij van u hebben, te wissen of om de wijze
          waarop wij deze gegevens gebruiken, te beperken</li>
      <li>toestemming voor de verwerking door ons van uw persoonsgegevens in te trekken
          (voor zover deze verwerking op toestemming gebaseerd is) </li>
      <li>een kopie van de u betreffende persoonsgegevens die u aan ons verstrekt hebt, in een
          gestructureerde, gangbare en machineleesbare vorm te ontvangen en die gegevens aan
          een andere partij over te dragen (voor zover de verwerking op toestemming of een
          overeenkomst gebaseerd is)</li>
      <li>bezwaar te maken tegen de verwerking van uw persoonsgegevens.</li>
    </ul>

    <p>Indien u een of meerdere van uw rechten wenst uit te oefenen of indien u andere vragen hebt
    over de manier waarop wij uw persoonsgegevens gebruiken, kunt u een e-mail sturen naar
    <a href={'mailto:' + gdprEmail }>{ gdprEmail }</a> of kunt u ons schrijven op het onderstaande adres:</p>

    <p><b>{ registeredOffice }</b></p>

    <p>Deze contactgegevens kunt u ook gebruiken indien u bij ons een klacht met betrekking tot uw
    privacy wilt indienen.</p>

    <h2>Recht op het indienen van een klacht</h2>

    <p>Indien u niet gelukkig bent met de manier waarop wij met uw persoonsgegevens of een
    privacy-vraag of -verzoek van u aan ons zijn omgegaan, hebt u het recht om een klacht in te
    dienen bij de gegevensbeschermingsautoriteit:</p>

    <p>
      Gegevensbeschermingsautoriteit<br />
      Drukpersstraat 35<br />
      <a href="mailto:contact@apd-gba.be">contact@apd-gba.be</a><br />
      <a href="https://www.dataprotectionauthority.be/" target="_blank" rel="noopener noreferrer">www.dataprotectionauthority.be</a>
    </p>

    <h2>Wijzigingen in deze privacyverklaring</h2>

    <p>Wij kunnen deze privacyverklaring op enig moment wijzigen of aanpassen.</p>

    <p>Om u te laten weten wanneer wij wijzigingen aanbrengen in deze privacyverklaring, zullen
    wij de herzieningsdatum bovenaan de pagina aanpassen. De nieuwe gewijzigde of aangepaste
    privacyverklaring geldt vanaf deze herzieningsdatum. Wie moedigen u dan ook aan om deze
    verklaring geregeld te bekijken om op de hoogte te blijven van de wijze waarop wij uw
    gegevens beschermen.</p>
  </div>
);

PrivacyStatementNl.propTypes = {
  gdprEmail: PropTypes.string.isRequired,
  registeredOffice: PropTypes.string.isRequired,
};
