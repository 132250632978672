// @flow
import {store} from '../util/store.js';


export class Translation {
  nl: ?string;
  fr: ?string;
  en: ?string;

  constructor(trans: any) {
    if (trans) {
      this.nl = trans.nl;
      this.fr = trans.fr;
      this.en = trans.en;
    }
  }

  get(): string {
    const currentLang = store.getState().t.lang;
    // $FlowFixMe
    const lang = this[currentLang];
    return lang || this.en || this.nl || this.fr || '';
  }

  slug(): string {
    return this.get()
      .toLowerCase()
      .replace(/ /g,'-')
      .replace(/[^\w-]+/g,'')
    ;
  }

  replace(substr: string, newSubstr: string): Translation {
    const self = this;
    return new Translation({
      nl: self.nl ? self.nl.replace(substr, newSubstr) : '',
      fr: self.fr ? self.fr.replace(substr, newSubstr) : '',
      en: self.en ? self.en.replace(substr, newSubstr) : '',
    });
  }
}
