import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from '../../util/prop-types';
import { Link } from 'react-router-dom';
import { ApplySearch } from '../../util/searchHelper';
import { MapCategoryToSearchResult, MapProductToSearchResult } from '../../util/mapper';

export class SearchBarComponent extends Component {
  SearchItems = [];
  message = '';

  static propTypes = {
    t: PropTypes.any.isRequired,
    products: PropTypes.ProductModelList.isRequired,
    categories: PropTypes.CategoryModelList.isRequired,
    classnames: PropTypes.arrayOf(PropTypes.string).isRequired
  }

  constructor(props) {
    super(props);
    this.handleSearch = this.handleSearch.bind(this);

    this.state = {
      result: [],
      searchValue: '',
      showMoreResults: false
    };
  }

  componentDidUpdate() {
    // TODO: This is duplicated in SearchPage.jsx
    // TODO: This is just happening for all items...
    //       It only takes about 12ms so it's ok...
    // console.time('search init');
    var itemsArray = [];

    if (this.props.products.length > 0) {
      this.props.products.forEach(prod => itemsArray.push(MapProductToSearchResult(prod)));
    }

    if (this.props.categories.length > 0) {
      this.props.categories.forEach(cat => itemsArray.push(MapCategoryToSearchResult(cat)));
    }

    this.SearchItems = itemsArray;
    // console.timeEnd('search init');
  }

  handleSearch(event) {
    this.message = event.target.value;
    var tempresults = ApplySearch(this.SearchItems, this.message);

    if (tempresults.length > 5) {
      this.setState({ result: tempresults.slice(0, 5), searchValue: this.message.toLowerCase(), showMoreResults: true });
    } else {
      this.setState({ result: tempresults.slice(0, 5), showMoreResults: false });
    }
  }

  clearSearch = () => {
    this.setState({ result: [], showMoreResults : false });
    this.message = '';
  }

  render() {
    const { t } = this.props;
    const classnames = ['searchbar', ...this.props.classnames];

    return (
      <div className={classnames.join(' ')}>
        <span className="fa fa-search searchbar__icon"/>
        <input type="text" placeholder={t('search.search')} onChange={this.handleSearch} value={this.message} />
        <div className="searchbar__result" >
          <div className="list-group">
            {this.state.result.map((item) => (
              <Link key={item.key} onClick={this.clearSearch} className="list-group-item" to={item.path}>{item.title.get()}</Link>
            ))}
            {this.state.showMoreResults &&
              <Link className="list-group-item" onClick={this.clearSearch} to={'/search?searchValue=' + this.state.searchValue} >
                {t('search.moreResults')}
                <i className="fa fa-arrow-alt-right"/>
              </Link>
            }
          </div>
        </div>
      </div>
    );
  }
}

const searchBarStateMapper = state => ({ products: state.prods, categories: state.cats, t: state.t });
export const SearchBar = connect(searchBarStateMapper)(SearchBarComponent);
