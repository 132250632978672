import {ActionTypes} from '../actions/ActionTypes.js';
import {Category} from '../models/CategoryModel.js';

export const cats = (state: Array<Category> = [], action: {type: string, payload: any}) => {
  switch (action.type) {
  case ActionTypes.CATEGORIES_FETCHED:
    // console.log('CATEGORIES_FETCHED', new Category(action.payload[0])); // eslint-disable-line
    // console.log('CATEGORIES_FETCHED', new Category(action.payload[1])); // eslint-disable-line
    // TODO: NameNl="SPECS WITHOUT CATEGORY" is not really a category
    // --> Save this in collection "config"?
    // TODO: Also complete nl/fr/en translations of the categeries (with a script)
    // TODO: ending dashes in slug: do a trim on the db... (in importer?)
    return action.payload.map(x => new Category(x));

  case ActionTypes.SET_LANGUAGE:
    return state.slice();

  case ActionTypes.INITIAL_LOAD: {
    const newState = state.slice();
    newState.forEach(cat => {
      const subCats = cat._categories.map(catId => state.find(c => c.id === catId));
      cat.setSubCategories(subCats);
    });
    return newState;
  }


  default:
    return state;
  }
};
