import React, {Component} from 'react';
import {PropTypes} from '../../util.js';
import {ProductCard} from '../product/ProductCard.jsx';
import Masonry from 'react-masonry-component';

const masonryOptions = {
  transitionDuration: 0
};

export class ProductList extends Component {
  static propTypes = {
    products: PropTypes.ProductModelList.isRequired,
  }

  render() {
    const {products} = this.props;
    return (
      <Masonry
        className="product-list"
        options={masonryOptions}
        disableImagesLoaded={false} // default false
        updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
      >
        {products.map(prod => <ProductCard key={prod.id} prod={prod} />)}
      </Masonry>
    );
  }
}
