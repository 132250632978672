import {createStore, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import * as ourReducers from '../reducers/index.js';
import {composeWithDevTools} from 'redux-devtools-extension';

const createStoreWithMiddleware = composeWithDevTools(
  applyMiddleware(thunk)
)(createStore);

export const store = createStoreWithMiddleware(combineReducers({
  ...ourReducers,
}), {});
