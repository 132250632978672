import React, {Component} from 'react';
import {PropTypes} from '../../util.js';
import {Link} from 'react-router-dom';
// import {connect} from 'react-redux';

export class CategoryLink extends Component {
  static propTypes = {
    cat: PropTypes.CategoryModel,
    children: PropTypes.any,
  }

  render() {
    const {cat, children, ...props} = this.props;

    if(!cat) {
      return null;
    }

    return (
      <Link to={cat.slug} onClick={() => window.scrollTo(0, 0)} {...props}>{children || cat.name}</Link>
    );
  }
}

//export const CategoryLink = connect(state => ({}))(CategoryLinkComponent);
