import React, {Component} from 'react';
import {PropTypes} from '../../util.js';

export class Price extends Component {
  static propTypes = {
    price: PropTypes.number.isRequired,
  }

  render() {
    const {price, ...props} = this.props;
    const formattedPrice = formatPrice(price);
    return <span {...props}>€ {formattedPrice}</span>;
  }
}

function formatPrice(price: number): string {
  if (!price) {
    return '';
  }

  if (price % 1 !== 0) {
    return price.toFixed(2).replace('.', ',');
  }
  return price.toString();
}
