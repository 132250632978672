import {ActionTypes} from '../actions/ActionTypes.js';
import {Product} from '../models/ProductModel.js';

export const prods = (state: Array<Product> = [], action: {type: string, payload: any}) => {
  switch (action.type) {
  case ActionTypes.PRODUCTS_FETCHED:
    //console.log('PRODUCTS_FETCHED', action.payload[0]); // eslint-disable-line
    return action.payload.map(x => new Product(x));
  case ActionTypes.PRODUCTS_VIEWS_FETCHED: {
    const newState = state.slice();
    newState.forEach(prod => {
      const views = action.payload.find(x => x.productId === prod.id);
      if (views) {
        prod.views = views.views;
      }
    });
    return newState;
  }
  case ActionTypes.INITIAL_LOAD:
  case ActionTypes.SET_LANGUAGE:
    return state.slice();
  default:
    return state;
  }
};
