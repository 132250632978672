import { Translation } from './TranslationModel';

export class SearchResult {
    title : Translation;
    image: String;
    price: Number;
    description: Translation;
    path : String;
    type : String;
    key: String;
}