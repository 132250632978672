import { useEffect } from 'react';
import Navigate from 'react-dom';
import { connect } from 'react-redux';
import { PropTypes } from '../../../util';
import { Redirect, Route } from 'react-router-dom';

const DeliveryOptions = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { t, deliveryAvailable } = props;

  if (deliveryAvailable) {

    return (
      <div className="row not-found">
        <div className="col-md-12" style={{ paddingBottom: 26 }}>
          <h1>{t('corona.title')}</h1>
          <div>{t('corona.wayOfWorking')}</div>
          <div>{t('corona.wayOfWorking2')}</div>

          <h2>{t('corona.pickupTitle')}</h2>
          <p>{t('corona.pickup')}</p>

          <h2>{t('corona.deliveryTitle')}</h2>
          <p>{t('corona.delivery')}</p>

          <p><b>{t('corona.noWeapons')}</b></p>

          <a href="mailto:info@stockoma.be">
            <img src="/images/order-now.png" alt={t('corona.order')} />
          </a>
        </div>
      </div>
    );
  } else {
    return <Redirect to='/' />;
  }
};

DeliveryOptions.propTypes = {
  t: PropTypes.func.isRequired,
  deliveryAvailable: PropTypes.bool.isRequired
};


export default connect(state => ({ t: state.t, deliveryAvailable: state.app.deliveryAvailable }))(DeliveryOptions);
