import React, {Component} from 'react';
import {PropTypes, cn, CategoryHelper} from '../../util.js';
import {connect} from 'react-redux';
import {CategoryLink} from './CategoryLink.jsx';
import {Icon} from '../controls.js';

// Show sub categories menu on big devices

class SubCategoriesMenuComponent extends Component {
  static propTypes = {
    category: PropTypes.CategoryModel.isRequired,
    cats: PropTypes.CategoryModelList.isRequired,
    prods: PropTypes.ProductModelList.isRequired,
    t: PropTypes.any,
    footer: PropTypes.node,
  }

  render() {
    const {prods, category} = this.props;
    const cats = new CategoryHelper(this.props.cats);
    const mainCategory = cats.getParent(category);

    return (
      <div>
        <li className="nav-header nav-main-cat-title">{mainCategory.name}</li>

        <div className="nav nav-section-menu sub-cats-menu">
          <ul className="nav nav-section-menu">
            {mainCategory.categories.sort((a, b) => a.name.localeCompare(b.name)).map(cat => {
              const isOpenCat = category.id === cat.id;
              const navLinkClasses = cn('nav-link', {active: isOpenCat});
              const prodCount = prods.filter(prod => prod.categories.includes(cat.id)).length;
              return (
                <li key={cat.id}>
                  <CategoryLink cat={cat} className={navLinkClasses}>
                    {cat.name} <small className="d-md-none d-lg-inline">({prodCount})</small>
                    <Icon fa="fa-angle-right" />
                  </CategoryLink>
                </li>
              );
            })}
          </ul>
          {this.props.footer}
        </div>
      </div>
    );
  }
}

export const SubCategoriesMenu = connect(state => ({cats: state.cats, prods: state.prods, t: state.t}))(SubCategoriesMenuComponent);
