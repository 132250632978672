import React, {Component} from 'react';
import {PropTypes, connect} from '../../../util.js';
import {ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

class PageSizeSelectorComponent extends Component {
  static propTypes = {
    sizes: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
    t: PropTypes.any.isRequired,
  }

  static defaultProps = {
    sizes: [15, 20, 25, 30, 35, 40, 45, 50],
  }

  constructor() {
    super();
    this.state = {isOpen: false};
  }

  render() {
    const {sizes, onChange, value, t} = this.props;
    return (
      <div>
        <span style={{marginRight: 6}}>{t('search.pageSize')}</span>
        <ButtonDropdown isOpen={this.state.isOpen} toggle={() => this.setState({isOpen: !this.state.isOpen})}>
          <DropdownToggle caret size="sm" color="dropdown">
            {value}
          </DropdownToggle>
          <DropdownMenu>
            {sizes.map(size => (
              <DropdownItem key={size} onClick={() => onChange(size)}>
                {size}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    );
  }
}

export const PageSizeSelector = connect(state => ({t: state.t}))(PageSizeSelectorComponent);
