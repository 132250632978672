import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { LanguageChanger } from './controls/LanguageChanger.jsx';
import { PropTypes } from '../../util.js';
import { Navbar, NavbarBrand } from 'reactstrap';
import { MobileMenu } from './Menu/MobileMenu.jsx';
import { ExtraCategoriesNavbar } from './Menu/ExtraCategoriesNavbar.jsx';
import { SearchBar } from '../search/SearchBar.jsx';

import { MailUsIcon, CallUsIcon, HeaderFacebookIcon/*, HeaderContactIcon*/ } from './controls/controls.js';

const subTitle = 'Military store';

class HeaderComponent extends Component {
  static propTypes = {
    t: PropTypes.any.isRequired,
    cats: PropTypes.CategoryModelList.isRequired,
    app: PropTypes.any.isRequired,
  }

  // TODO: new logo here (logo4.jpg)

  render() {
    const { t, app } = this.props;
    const mdUp = app.device !== 'xs' && app.device !== 'sm';
    return (
      <section className="header">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-6 col-12">
            <Navbar light>
              <NavbarBrand tag={Link} to="/">
                {/*<img src="/images/logo4.jpg" />*/}
                {t('title')}
                <div className="nav-bar-subtitle">{subTitle}</div>
              </NavbarBrand>
              <ul className="navbar-nav ml-auto navbar-right-items">
                <li><LanguageChanger hideLabel={app.device === 'xs'} /></li>
                {mdUp ? <li className="nav-icon"><MailUsIcon /></li> : null}
                {mdUp ? <li className="nav-icon"><CallUsIcon /></li> : null}
                {app.device !== 'xs' ? <li style={{ marginRight: 8 }}><HeaderFacebookIcon /></li> : null}
              </ul>
              <MobileMenu />
            </Navbar>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-6 col-12">
            <div className='row justify-content-end'>
              <div className="col-lg-3 col-md-7 col-12">
                <SearchBar classnames={['searchbar--right']} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {mdUp ? <ExtraCategoriesNavbar /> : null}
          </div>
        </div>
      </section>
    );
  }
}
const mapStateToProps = state => ({ cats: state.cats, app: state.app, t: state.t });
export const Header = connect(mapStateToProps)(HeaderComponent);
