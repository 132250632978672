import React from 'react';
import PropTypes from 'prop-types';

export const PrivacyStatementEn = ({gdprEmail, registeredOffice}) => (
  <div>
    <h1>Privacy Statement</h1>

    <h2>Privacy Statement Summary</h2>

    <p>This privacy statement explains what information we gather about you, what we use that
    information for and who we give that information to. It also sets out your rights in relation to
    your information and who you can contact for more information or queries.</p>

    <p>Your information will be used in accordance with the consents that you have given, with Act
    of 30 july 2018 on the protection of natural persons with regards to the processing of personal
    data and with European Regulation 2016/679 of the European Parliament and of the Council
    of 27 April 2016 (entered into force on 25 May 2018) on the protection of natural persons
    with regard to the processing of personal data and on the free movement of such data
    (GDPR).This Privacy Notice is also intended to assist you in making informed decisions
    when using our website.</p>

    <h2>What this privacy statement covers?</h2>

    <p>This privacy statement sets out how we will collect, handle, store and protect information
    about you when:</p>

    <ul>
      <li>providing services to you or our clients</li>
      <li>you use “our Website” or</li>
      <li>performing any other activities that form part of the operation of our business, as
          described in further detail below.</li>
    </ul>

    <p>When we refer to “our Website” or “this Website” in this policy we mean the specific
    webpages of Stockoma.be.</p>

    <p>This privacy statement also contains information about when we share your personal data
    with other members of the Stockoma Network and other third parties (for example, our service
    providers).</p>

    <p>In this privacy statement, your information is sometimes called “personal data” or “personal
    information”. We may also sometimes collectively refer to handling, collecting, protecting
    and storing your personal information as “processing” such personal information.</p>

    <h2>What information we collect?</h2>

    <p>In the course of providing services to you or our clients and performing due diligence checks
    in connection with our services (or discussing possible services we might provide), we will
    collect or obtain personal data about you. We may also collect personal data from you when
    you use our Website.</p>

    <p>We may collect or obtain such data because you give it to us (for example in a form on our
    Website), because other people give that data to us (for example your employer or adviser, or
    third party service providers that we use to help operate our business) or because it is publicly
    available.</p>

    <p>We may also collect or obtain personal data from you because we observe or infer that data
    about you from the way you interact with us or others. For example, to improve your
    experience when you use our Website and ensure that it is functioning effectively, we (or our
    service providers) may use cookies (small text files stored in a user’s browser) and Web
    beacons which may collect personal data. Additional information on how we use cookies and
    other tracking technologies and how you can control these can be found on our Cookiepolicy.</p>

    <p>The personal data that we collect or obtain may include: your name; age; date of birth;
    gender; e-mail address; telephone number; home address; country of residence; lifestyle and
    social circumstances (for example, your pastimes); family circumstances (for example, your
    marital status and dependents); employment and education details (for example, the
    organization you work for, your job title and your education details); financial and tax-related
    information (for example your income and tax residency); your postings on any blogs,
    forums, wikis and any other social media applications and services that we provide; your IP
    address; your browser type and language; your access times; complaint details; details of how
    you use our products and services; details of how you like to interact with us and other similar
    information.</p>

    <p>The personal data we collect may also include so called ‘sensitive’ or ‘special categories’ of
    personal data, such as details about your: dietary requirements (for example, where Stockoma
    would like to provide you with lunch during a meeting), health (for example, so that we can
    make reasonable accommodations for you in our buildings, products and services) and sexual
    orientation (for example if you provide us with details of your spouse or partner).</p>

    <p>The types of personal data and special categories of personal data that we collect may vary
    depending on the nature of the services that we provide to you or our client, or how you use
    our Website. In some rare circumstances, we might also gather other special categories of
    personal data about you because you volunteer that data to us or we are required to gather that
    data as a result of legal requirements imposed on us.</p>

    <p>Where we are provided with personal data about you by our client, we use reasonable efforts
    to include clauses into the contract with the client that will require them to comply with the
    privacy laws and regulations relevant to that information; this may include, for example, that
    the client has provided you with notice of the collection (and other matters) and has obtained
    any necessary consent for us to process that information as described in this privacy
    statement.</p>

    <p>We understand the importance of protecting children&#39;s privacy. Our Website and services are
    not designed for, or intentionally targeted at, children. It is not our policy to intentionally
    collect or store information about children.</p>

    <h2>How we use information about you?</h2>

    <h3>Use of personal information to provide services to our clients</h3>

    <p>We will use your personal data to provide you or our client with the requested services. As
    part of this, we may also use your personal data in the course of correspondence relating to
    the services. Such correspondence may be with you, our client, other members of the Stockoma
    Network, our service providers or competent authorities. We may also use your personal data
    to conduct due diligence checks relating to the services. Because we provide a wide range of
    services to our clients, the way we use personal data in relation to our services also varies. For
    example, we might use personal data:</p>

    <ul>
      <li>
        about a client to help him/her complete a tax returnabout a client’s employees to
        contact him during the performance of our services and keep them informed of the
        project’s progress or in order to communicate them our projects’ deliverables.
      </li>
    </ul>

    <p>Use of personal information for other activities that form part of the operation of our business
    We may also use your personal data for the purposes of, or in connection with:</p>

    <ul>
      <li>applicable legal or regulatory requirements</li>
      <li>requests and communications from competent authorities</li>
      <li>administrative purposes</li>
      <li>financial accounting, invoicing and risk analysis purposes</li>
      <li>client relationship purposes, which may involve: (i) sending you thought leadership or
          details of our products and services that we think might be of interest to you; (ii)
          contacting you to receive feedback on services; and (iii) contacting you for other
          market or research purposes. In all above cases, we give you the opportunity to decline
          our offers and requests at any time. In specific cases if needed, for example in case
          you are not yet a client, we will make sure to obtain your prior consent before sending
          you communications’ materials or other marketing requests (see also below, “The
          legal grounds we use for processing personal information”);</li>
      <li>recruitment and business development purposes (for example testimonials from a
          client’s employees may be used as part of our recruitment and business development
          materials with that employee’s permission)</li>
      <li>services we receive from our professional advisors, such as lawyers, accountants and
          consultants</li>
      <li>protecting our rights and those of our clients.</li>
    </ul>

    <h3>Use of personal information collected via our Website</h3>

    <p>In addition to the purposes connected to the operation of our business above, we may also use
    your personal data collected via our Website:</p>

    <ul>
      <li>to manage and improve our Website</li>
      <li>to tailor the content of our Website to provide you with a more personalized
          experience and draw your attention to information about our products and services that
          may be of interest to you</li>
      <li>to manage and respond to any request you submit through our Website.</li>
    </ul>

    <h2>The legal grounds we use for processing personal information</h2>

    <p>We are required by law to set out in this privacy statement the legal grounds on which we rely
    in order to process your personal data.</p>

    <p>As a result, we use your personal data for the purposes outlined above because : (a) of the
    legal and regulatory obligations that we are subject to, such as keeping records for regulatory
    or tax purposes or providing information to a public body or law enforcement agency or (b)
    the information is required in order to provide our services to you or our client. (c) of our
    legitimate interests in the effective delivery of our services to you or our client; (d) of our
    legitimate interests in the effective and lawful operation of our business so long as such
    interests are not outweighed by your interests;</p>

    <p>To the extent that we process any sensitive personal data relating to you for any of the
    purposes outlined above, we will do so because either: (i) you have given us your explicit
    consent to process that data; (ii) we are required by law to process that data in order to ensure
    we meet our ‘know your client’ and ‘anti-money laundering’ obligations (or other legal
    obligations imposed on us); (iii) the processing is necessary to carry out our obligations under
    employment, social security or social protection law; (iv) the processing is necessary for the
    establishment, exercise or defence of legal claims or (v) you have made the data public.</p>

    <p>Where we are legally required to obtain your explicit consent to provide you with certain
    marketing materials, we will only provide you with such marketing materials where we have
    obtained such consent from you.</p>

    <h2>Who we disclose your information to?</h2>

    <p>In connection with one or more of the purposes outlined in the “How we use information
    about you?” section above, we may disclose details about you to: third parties that provide
    services to us; competent authorities (including courts and authorities regulating us); your
    employer and/or their advisers; your advisers; organizations that help us reduce the incidence
    of fraud and other third parties that reasonably require access to personal data relating to you
    for one or more of the purposes outlined in the “How we use information about you?” section
    above.</p>

    <p>Our Website hosts various blogs, forums, wikis and other social media applications or
    services that allow you to share content with other users (collectively “Social Media
    Applications”). Importantly, any personal information that you contribute to these Social
    Media Applications can be read, collected and used by other users of the application. We have
    little or no control over these other users and, therefore, we cannot guarantee that any
    information that you contribute to any Social Media Applications will be handled in
    accordance with this privacy statement.</p>

    <p>Please note that some of the recipients of your personal data referenced above may be based
    in countries outside of the European Union whose laws may not provide the same level of
    data protection. In such cases, we will ensure that there are adequate safeguards in place to
    protect your personal data that comply with our legal obligations. The adequate safeguard
    might be a data transfer agreement with the recipient based on standard contractual clauses
    approved by the European Commission for transfers of personal data to third countries.</p>

    <p>We may also need to disclose your personal data if required to do so by law, a regulator or
    during legal proceedings.</p>

    <p>We may share non-personal, de-identified and aggregated information with third parties for
    several purposes, including data analytics, research, submissions, thought leadership and
    promotional purposes.</p>

    <h2>Protection of your personal information</h2>

    <p>We use a range of physical, electronic and managerial measures to ensure that we keep your
    personal data secure, accurate and up to date. These measures include:</p>

    <ul>
      <li>education and training to relevant staff to ensure they are aware of our privacy
          obligations when handling personal data</li>
      <li>administrative and technical controls to restrict access to personal data on a ‘need to
          know’ basis</li>
      <li>technological security measures, including fire walls, encryption and anti-virus
          software</li>
      <li>physical security measures, such as staff security passes to access our premises.</li>
    </ul>

    <p>Although we use appropriate security measures once we have received your personal data, the
    transmission of data over the internet (including by e-mail) is never completely secure. We
    endeavor to protect personal data, but we cannot guarantee the security of data transmitted to
    us or by us.</p>

    <h2>How long we keep your information for?</h2>

    <p>We will hold your personal data on our systems for the longest of the following periods: (i) as
    long as is necessary for the relevant activity or services; (ii) any retention period that is
    required by law; or (iii) the end of the period in which litigation or investigations might arise
    in respect of the services.</p>

    <h3>Your rights</h3>
    <p>You have various rights in relation to your personal data. In particular, you have a right to:</p>

    <ul>
      <li>obtain confirmation that we are processing your personal data and request a copy of
          the personal data we hold about you</li>
      <li>ask that we update the personal data we hold about you, or correct such personal data
          that you think is incorrect or incomplete</li>
      <li>ask that we delete personal data that we hold about you, or restrict the way in which
          we use such personal data</li>
      <li>withdraw consent to our processing of your personal data (to the extent such
          processing is based on consent)</li>
      <li>receive a copy of the personal data concerning you, which you have provided to us, in
          a structured, commonly used and machine-readable format and to transmit such
          personal data to another party (to the extent the processing is based on consent or a
          contract)</li>
      <li>object to our processing of your personal data.</li>
    </ul>

    <p>To exercise any of your rights, or if you have any other questions about our use of your
    personal data, please email <a href={'mailto:' + gdprEmail }>{ gdprEmail }</a> or write to us at the address below:</p>

    <p><b>{ registeredOffice }</b></p>

    <p>You may also use these contact details if you wish to make a complaint to us relating to your
    privacy.</p>

    <h2>Right to complain</h2>

    <p>If you are unhappy with the way we have handled your personal data or any privacy query or
    request that you have raised with us, you have a right to complain to the EU Data Protection
    Authority (“DPA”) in your jurisdiction:</p>

    <p>
      Data Protection Authority<br />
      Rue de la Presse 35<br />
      <a href="mailto:contact@apd-gba.be">contact@apd-gba.be</a><br />
      <a href="https://www.dataprotectionauthority.be/" target="_blank" rel="noopener noreferrer">www.dataprotectionauthority.be</a>
    </p>

    <h2>Changes to this privacy statement</h2>

    <p>We may modify or amend this privacy statement from time to time.</p>

    <p>To let you know when we make changes to this privacy statement, we will amend the revision
    date at the top of this page. The new modified or amended privacy statement will apply from
    that revision date. Therefore, we encourage you to periodically review this statement to be
    informed about how we are protecting your information.</p>
  </div>
);


PrivacyStatementEn.propTypes = {
  gdprEmail: PropTypes.string.isRequired,
  registeredOffice: PropTypes.string.isRequired,
};
