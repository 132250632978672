import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from '../../../util';

class ContactComponent extends Component {
  static propTypes = {
    t: PropTypes.any.isRequired,
    config: PropTypes.any.isRequired,
    className: PropTypes.string,
  }

  render() {
    const {t, config, className} = this.props;
    return (
      <div className={className}>
        <strong className="footer-title">{t('footer.contact')}</strong>
        <br/><a href={'mailto:' + config.contact.email}>{config.contact.email}</a>
        <br/><a href={'tel:' + config.contact.telephoneLink}>{config.contact.telephone}</a>
      </div>
    );
  }
}

export const Contact = connect(state => ({config: state.config, t: state.t}))(ContactComponent);
