import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from '../../../util.js';

class AddressComponent extends Component {
  static propTypes = {
    t: PropTypes.any.isRequired,
    config: PropTypes.any.isRequired,
    className: PropTypes.string,
  }

  render() {
    const {t, config, className} = this.props;
    return (
      <div className={className}>
        <strong className="footer-title">{t('footer.address')}</strong>
        <br/>{config.contact.address1}
        <br/>{config.contact.address2}
        <br/>{t('footer.addressInstructions')}
      </div>
    );
  }
}

export const Address = connect(state => ({config: state.config, t: state.t}))(AddressComponent);
