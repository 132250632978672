import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from '../util.js';


class ContactPageComponent extends Component {
  static propTypes = {
    config: PropTypes.any,
  }

  render() {
    //const {config} = this.props;
    return (
      <div>
        contact us
      </div>
    );
  }
}

const mapStateToProps = state => ({config: state.config});
export const ContactPage = connect(mapStateToProps)(ContactPageComponent);
