import React, {Component} from 'react';
import {PropTypes} from '../../util.js';
import {ProductImage} from './ProductImage.jsx';
import {Price} from '../controls.js';
import {ProductLink} from './ProductLink.jsx';

export class ProductCard extends Component {
  static propTypes = {
    prod: PropTypes.ProductModel.isRequired,
  }

  render() {
    const {prod} = this.props;
    return (
      <ProductLink prod={prod} className="product-card">
        <ProductImage prod={prod} className="img img-fluid mx-auto d-block" />
        {prod.price ? <Price price={prod.price} className="product-image-price" /> : null}
        <div className="product-card-name">{prod.name}</div>
      </ProductLink>
    );
  }
}
