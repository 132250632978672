// @flow
import {ActionTypes} from '../actions/ActionTypes.js';

// state.t usage: this.props.t('nav.allCats')

import {t as transFn} from '../util/trans.js';
import {trans as transNl} from '../trans/trans-nl.js';
import {trans as transFr} from '../trans/trans-fr.js';
import {trans as transEn} from '../trans/trans-en.js';

const transes = {
  nl: transFn.bind(null, transNl),
  fr: transFn.bind(null, transFr),
  en: transFn.bind(null, transEn),
};


export const allLanguages = Object.keys(transes);


allLanguages.forEach(lang => {
  const trans = transes[lang];
  trans.lang = lang;
});



function getLanguageObject(lang: string) {
  if (!lang) {
    lang = getDefaultLanguage();
  }
  switch (lang) {
  case 'en':
    return transes.en;
  case 'fr':
    return transes.fr;
  case 'nl':
  default:
    return transes.nl;
  }
}




export const t = (state: any = transes.nl, action: {type: string, payload: any}) => {
  switch (action.type) {
  case ActionTypes.SET_LANGUAGE: {
    const lang = action.payload;
    if (lang) {
      localStorage.setItem('lang', lang);
    }
    return getLanguageObject(lang);
  }

  default:
    return state;
  }
};



function getDefaultLanguage(): string {
  const savedLang = localStorage.getItem('lang');
  if (savedLang) {
    return savedLang;
  }

  const lang = getBrowserLocale();
  return lang || 'nl';
}



function getBrowserLocale(): string {
  // https://github.com/maxogden/browser-locale/blob/master/index.js
  if (navigator.languages && navigator.languages.length) {
    // latest versions of Chrome and Firefox set this correctly
    return navigator.languages[0];
  } else if (navigator.userLanguage) {
    // IE only
    // $FlowFixMe
    return navigator.userLanguage;
  } else {
    // latest versions of Chrome, Firefox, and Safari set this correctly
    return navigator.language;
  }
}
