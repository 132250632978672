// @flow


export const t = function(trans: any, key: string, params: any): string {
  var str;
  if (key.indexOf('.') === -1) {
    str = trans[key];
  } else {
    // $FlowFixMe
    str = key.split('.').reduce((o, i) => o[i], trans);
  }

  if (str === undefined) {
    return key;
  }

  if (str.indexOf('{}') !== -1) {
    return str.replace('{}', params);

  } else if (typeof params === 'object') {
    Object.keys(params).forEach(function(paramKey) {
      str = str.replace('{' + paramKey + '}', params[paramKey]);
    });
  }

  return str;
};
