import React, {Component} from 'react';
import {PropTypes, connect} from '../../../util.js';
import {ProductImage} from '../ProductImage.jsx';
import Lightbox from 'react-image-lightbox';

class ProductDetailGalleryComponent extends Component {
  static propTypes = {
    t: PropTypes.any.isRequired,
    product: PropTypes.ProductModel.isRequired,
  }

  constructor() {
    super();
    this.state = {
      isOpen: false,
      imageIndex: 0,
    };
  }

  render() {
    const {product} = this.props;
    if (!product.imageCount) {
      return null;
    }

    var modalGallery = null;
    if (this.state.isOpen) {
      const imageIndex = this.state.imageIndex;
      const images = product.getImageUrls();

      modalGallery = (
        <Lightbox
          mainSrc={images[imageIndex].path}
          nextSrc={images[(imageIndex + 1) % images.length].path}
          prevSrc={images[(imageIndex + images.length - 1) % images.length].path}
          onCloseRequest={() => this.setState({isOpen: false})}
          onMovePrevRequest={() => this.setState({imageIndex: (imageIndex + images.length - 1) % images.length})}
          onMoveNextRequest={() => this.setState({imageIndex: (imageIndex + 1) % images.length})}
          ariaHideApp={false}
        />
      );
    }

    return (
      <div>
        <ProductImage prod={product} className="img-thumbnail clickable" onClick={() => this.setState({isOpen: true})} />
        <ProductGalleryThumbs product={product} onOpenGallery={imageIndex => this.setState({imageIndex, isOpen: true})} />
        {modalGallery}
      </div>
    );
  }
}


export const ProductDetailGallery = connect(state => ({t: state.t}))(ProductDetailGalleryComponent);


class ProductGalleryThumbsComponent extends Component {
  static propTypes = {
    device: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']).isRequired,
    product: PropTypes.ProductModel.isRequired,
    onOpenGallery: PropTypes.func.isRequired,
    t: PropTypes.any.isRequired,
  }

  render() {
    const {t, device, product, onOpenGallery} = this.props;
    if (!product.imageCount) {
      return null;
    }

    var showThumbs = 3;
    if (device === 'xl') {
      showThumbs = 4;
    }

    var images = [];
    for (let i = 2; i <= Math.min(showThumbs + 1, product.imageCount); i++) {
      images.push(
        <div className="img-thumb" key={i}>
          <ProductImage
            prod={product}
            imageIndex={i}
            className="img-thumbnail clickable"
            onClick={() => onOpenGallery(i - 1)}
          />
        </div>
      );
    }

    const notVisiblePitures = product.imageCount - showThumbs - 1;
    return (
      <div>
        <div className="img-thumbs">
          {images}
        </div>
        {notVisiblePitures > 0 ? (
          <button
            onClick={() => onOpenGallery(showThumbs + 1)}
            className="btn btn-info btn-sm open-gallery-btn clickable"
          >
            {t('product.morePicture' + (notVisiblePitures === 1 ? '' : 's'), {cnt: notVisiblePitures})}
          </button>
        ) : null}
      </div>
    );
  }
}

export const ProductGalleryThumbs = connect(state => ({device: state.app.device, t: state.t}))(ProductGalleryThumbsComponent);
