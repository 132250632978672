// @flow
import {ActionTypes} from '../actions/ActionTypes.js';

// https://getbootstrap.com/docs/4.0/layout/overview/#responsive-breakpoints
// ATTN: xl: 1587 is a self-invented cutoff made to change container-fluid to container
// If you change this value you will want to check if the design is still ok on very large devices and you
// need to update the media query in the _app.scss as well
const deviceBreakpoints = {xs: 576, sm: 768, md: 992, lg: 1200, xl: 1587};

export const deviceSameOrLargerThan = function(toCompare: string): bool {
  const deviceSizes = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

  if (!deviceSizes.indexOf(toCompare)) {
    throw new Error(`DeviceSize ${toCompare} not supported, typo?`);
  }
  if (deviceSizes.indexOf(getDeviceSize()) >= deviceSizes.indexOf(toCompare)) {
    return true;
  }
  return false;
};


// state.app: Settings NOT from the backend
const defaultAppState = {
  isLoaded: false,
  device: getDeviceSize(),
  deviceSameOrLargerThan: deviceSameOrLargerThan,
  deliveryAvailable: false,
  mobileMenuOpen: false,
  category: {
    pageSize: 15,
    sortBy: 'popularity-desc',
  },
};

export const app = (state: any = defaultAppState, action: {type: string, payload: any}) => {
  switch (action.type) {
  case ActionTypes.INITIAL_LOAD:
    return {...state, isLoaded: true};

  case ActionTypes.VIEWPORT_RESIZE:
    return {...state, device: getDeviceSize()};


  case ActionTypes.TOGGLE_MENU:
    return {...state, mobileMenuOpen: !state.mobileMenuOpen};


  case ActionTypes.CATEGORY_SORT_CHANGED: {
    const newSortBy = action.payload;
    const newCat = Object.assign({}, state.category, {sortBy: newSortBy});
    return {...state, category: newCat};
  }

  case ActionTypes.CATEGORY_PAGESIZE_CHANGED: {
    const newPageSize = action.payload;
    const newCat = Object.assign({}, state.category, {pageSize: newPageSize});
    return {...state, category: newCat};
  }

  default:
    return state;
  }
};


function getDeviceSize(): string {
  const width = typeof window === 'object' ? window.innerWidth : null;
  if (width) {
    if (width < deviceBreakpoints.xs) {
      return 'xs';
    }
    if (width < deviceBreakpoints.sm) {
      return 'sm';
    }
    if (width < deviceBreakpoints.md) {
      return 'md';
    }
    if (width < deviceBreakpoints.lg) {
      return 'lg';
    }
    if (width < deviceBreakpoints.xl) {
      return 'xl';
    }
    return 'xxl';
  }
  return 'md';
}
