import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import {Spinner} from '../Spinner.jsx';
import {NoMatch} from '../../skeleton/NoMatch.jsx';

export const CategoryIdResolverDecorator = ComposedComponent => connect(state => ({cats: state.cats}))(
  class CategoryIdResolverComponent extends Component {
    static propTypes = {
      match: PropTypes.shape({
        params: PropTypes.shape({
          slug: PropTypes.string.isRequired,
          catId: PropTypes.string.isRequired,
          page: PropTypes.string,
        }).isRequired,
      }).isRequired,
      cats: PropTypes.array.isRequired,
    }

    render() {
      const {match, cats, ...props} = this.props;
      if (cats.length === 0) {
        return <Spinner />;
      }

      const categoryId = match.params.catId;
      var category = cats.find(cat => cat.id === categoryId);

      if (!category) {
        // TODO: move the catId -> cat "calculation" to a helper func
        // function getCategory(allCategories) {...}
        const allSubCats = cats.reduce((acc, next) => {
          acc.push.apply(acc, next.categories);
          return acc;
        }, []);
        category = allSubCats.find(cat => cat.id === categoryId);
      }

      if (!category) {
        return <NoMatch msg="notFound.catMsg" />;
      }

      return <ComposedComponent category={category} page={parseInt(match.params.page || 1, 10)} {...props} />;
    }
  }
);

export const CategoryIdResolver = CategoryIdResolverDecorator;
