import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {PropTypes, CategoryHelper, cn} from '../../../util.js';
import {Icon, CategoryLink} from '../../controls.js';

const BreadCrumbIcon = () => <Icon fa="fa-angle-double-right" />;

class CategoryCrumbComponent extends Component {
  static propTypes = {
    category: PropTypes.CategoryModel.isRequired,
    cats: PropTypes.CategoryModelList.isRequired,
    t: PropTypes.any.isRequired,
    className: PropTypes.string,
    showFull: PropTypes.bool,
  }

  render() {
    const {t, category, showFull} = this.props;

    const cats = new CategoryHelper(this.props.cats);
    const mainCategory = cats.getParent(category);

    return (
      <div className={cn('category-breadcrumb', this.props.className)}>
        <Link to="/">
          <Icon fa="fa-home" />
        </Link>

        <BreadCrumbIcon />
        <Link to="/catalog">{t('catalog')}</Link>

        {mainCategory !== category ? [
          <BreadCrumbIcon key="crumb-separator" />,
          <CategoryLink key="maincat" cat={mainCategory} />
        ] : null}

        {showFull ? [
          <BreadCrumbIcon key="crumb-separator2" />,
          <CategoryLink key="maincat" cat={category} />
        ] : null}
      </div>
    );
  }
}

const mapStatetoProps = state => ({device: state.app.device, cats: state.cats, t: state.t});
export const CategoryCrumb = connect(mapStatetoProps)(CategoryCrumbComponent);
