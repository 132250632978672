// @flow
import {ActionTypes} from '../actions/ActionTypes.js';

// state.config: Settings from the backend
const defaultConfig = {
  ga: 'UA-63147733-1',
  weaponLicenseNumber: '2/21/13/0029',
  companyNumber: 'BE0420046028',
  acceptBancontactFrom: 15.0,
  contact: {
    email: 'info@stockoma.be',
    telephone: '02 452 51 38',
    telephoneLink: '+3224525138',
    address1: 'Brusselsesteenweg 339',
    address2: '1730 Kobbegem (Asse)'
  },
  socialMedia: {
    facebook: 'https://www.facebook.com/stockoma',
    facebookAppId: '1842472936051354',
    facebookPostLink: 'https://www.facebook.com/stockoma/photos/a.855618611192077.1073741829.844539068966698/1663129873774276/?type=3&theater',
    twitter: 'https://twitter.com/stockoma_americ',
    linkedIn: 'https://www.linkedin.com/company/10303867'
  },
  openingHours: {
    monday: [['9:00', '12:30'], ['13:15', '18:00']],
    tuesday: [['9:00', '12:30'], ['13:15', '18:00']],
    wednesday: [['9:00', '12:30'], ['13:15', '18:00']],
    thursday: [['9:00', '12:30'], ['13:15', '18:00']],
    friday: [['9:00', '12:30'], ['13:15', '18:00']],
    saturday: [['9:00', '17:30']],
    sunday: null,
  },
  newOpeningHours: {
    monday: null,
    tuesday: [['10:00', '12:30'], ['13:15', '18:00']],
    wednesday: [['10:00', '12:30'], ['13:15', '18:00']],
    thursday: [['10:00', '12:30'], ['13:15', '18:00']],
    friday: [['10:00', '12:30'], ['13:15', '18:00']],
    saturday: [['10:00', '17:30']],
    sunday: null,
  },
  languages: { // ATTN: adding one here means adding a flag icon in img folder
    nl: 'Nederlands',
    fr: 'Français',
    en: 'English',
  },
};

export const config = (state: any = defaultConfig, action: {type: string, payload: any}) => {
  switch (action.type) {
  case ActionTypes.CONFIG_FETCHED:
    return Object.assign({}, state, action.payload);
  default:
    return state;
  }
};
