import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from '../../util.js';
import {CatalogPage} from '../CatalogPage.jsx';

class NoMatchComponent extends Component {
  static propTypes = {
    t: PropTypes.any.isRequired,
    msg: PropTypes.string,
  }

  render() {
    const {t, msg} = this.props;
    return (
      <div className="row not-found">
        <div className="col-md-12">
          <h1>{t('notFound.title')}</h1>

          <div>{msg ? t(msg) : t('notFound.msg')}</div>
          <h2>{t('notFound.catalogInstead')}</h2>

          <CatalogPage />

        </div>
      </div>
    );
  }
}

export const NoMatch = connect(state => ({t: state.t}))(NoMatchComponent);
