import React from 'react';
import {cn} from '../../../util.js';
import {Icon, Price} from '../../controls.js';

export const ProductPrice =({price, className, ...props}) => { // eslint-disable-line
  if (!price) {
    return null;
  }

  return (
    <div className={cn('product-price', className)} {...props}>
      <Icon fa="fa-tag" />
      <Price price={price} />
    </div>
  );
};
