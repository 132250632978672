import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes, CategoryHelper} from '../../../util.js';
import {Navbar} from 'reactstrap';
import {CategoryLink} from '../../controls.js';

export const ExtraCategoriesNavbar = connect(state => ({cats: state.cats, t: state.t}))(class extends Component {
  static propTypes = {
    cats: PropTypes.CategoryModelList.isRequired,
    t: PropTypes.any.isRequired,
  }
  render() {
    const cats = new CategoryHelper(this.props.cats);
    return (
      <Navbar light className="navbar-main-cats">
        <ul className="navbar-nav mx-auto">
          {cats.getMain().map(cat => <li key={cat.id}><CategoryLink cat={cat} /></li>)}
        </ul>
      </Navbar>
    );
  }
});
