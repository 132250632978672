import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from '../../util.js';
import {Icon} from './Icon.jsx';

class TwitterIconComponent extends Component {
  static propTypes = {
    twitter: PropTypes.string.isRequired,
  }

  render() {
    const {twitter} = this.props;
    return (
      <a href={twitter} target="_blank" rel="noopener noreferrer">
        <Icon fa="fa-twitter fa-2x" />
      </a>
    );
  }
}

const mapStateToProps = state => ({twitter: state.config.socialMedia.twitter});
export const TwitterIcon = connect(mapStateToProps)(TwitterIconComponent);
