import React from 'react';
import {Switch, Route} from 'react-router-dom';

import {CategoryPage} from './components/category/CategoryPage.jsx';
import {ProductPage} from './components/product/ProductPage.jsx';
import {ContactPage} from './components/ContactPage.jsx';
import {CatalogPage} from './components/CatalogPage.jsx';
import {Home} from './components/home/HomePage.jsx';
import {NoMatch} from './components/skeleton/NoMatch.jsx';
import {PrivacyStatement} from './components/skeleton/legal/PrivacyStatement';
import {CookiePolicy} from './components/skeleton/legal/CookiePolicy';
import DeliveryOptions from './components/skeleton/delivery/DeliveryOptions';
import {SearchPage} from './components/search/SearchPage';

export const Routes = (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/category/:slug/:catId/:page?" component={CategoryPage} />
    <Route path="/product/:slug/:prodId/:catId" component={ProductPage} />
    <Route path="/contact" component={ContactPage} />
    <Route path="/catalog" component={CatalogPage} />
    <Route path="/privacy-statement" component={PrivacyStatement} />
    <Route path="/cookie-policy" component={CookiePolicy} />
    <Route path="/delivery-options" component={DeliveryOptions} />
    <Route path="/search" component={SearchPage} />
    <Route component={NoMatch} />
  </Switch>
);
