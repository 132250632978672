import React, {Component} from 'react';
import {PropTypes, CategoryHelper, connect} from '../../../util.js';
import {CategoryLink} from '../../controls.js';


const SubCategoriesTagCloudComponent = class extends Component {
  static propTypes = {
    cats: PropTypes.CategoryModelList.isRequired,
    prods: PropTypes.ProductModelList.isRequired,
    category: PropTypes.CategoryModel.isRequired,
    variableFontSize: PropTypes.bool,
  }

  static defaultProps = {
    variableFontSize: true,
  }

  render() {
    const {category} = this.props;

    if (!category.categories.length) {
      return null;
    }


    const cats = new CategoryHelper(this.props.cats, this.props.prods);
    return (
      <div className="sub-cats-cloud">
        {cats.getWithViewCount(category).map(cat => (
          <SubCat
            key={cat.category.id}
            category={cat.category}
            views={cat.views}
            variableFontSize={this.props.variableFontSize}
          />
        ))}
      </div>
    );
  }
};


const mapStateToProps = state => ({config: state.config, cats: state.cats, prods: state.prods});
export const SubCategoriesTagCloud = connect(mapStateToProps)(SubCategoriesTagCloudComponent);



const SubCat = class extends Component {
  static propTypes = {
    category: PropTypes.CategoryModel.isRequired,
    views: PropTypes.number.isRequired,
    variableFontSize: PropTypes.bool,
  }

  getFontSize(viewCount: number): number {
    if (!this.props.variableFontSize) {
      return undefined;
    }

    // TODO: hardcoded viewCounts will not go well as the site is visited
    const sizeLookup = [
      {to: 1000, fs: 14},
      {to: 5000, fs: 15},
      {to: 10000, fs: 16},
      {to: 20000, fs: 17},
      {to: 50000, fs: 18},
    ];

    for (let i = 0; i < sizeLookup.length; i++) {
      const lookup = sizeLookup[i];
      if (lookup.to > viewCount) {
        return lookup.fs;
      }
    }
    return sizeLookup[sizeLookup.length - 1].fs;
  }

  render() {
    const {category, views} = this.props;
    return (
      <CategoryLink cat={category} style={{fontSize: this.getFontSize(views)}}>
        {category.name}
      </CategoryLink>
    );
  }
};
