import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from '../../util';
import {CategoryLink} from '../category/CategoryLink';

export class CategoriesHomeComponent extends Component {
  static propTypes = {
    app: PropTypes.any,
    cats: PropTypes.CategoryModelList.isRequired,
  }

  render() {
    const {cats} = this.props;
    const baseUrl = process.env.REACT_APP_POPULAR_IMAGES + '/';

    const categories = cats.filter(c => c.mainCategoryImg)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(c => ({url: `${baseUrl}${c.mainCategoryImg}`, category: c}));

    return (
      <section className="categories-home">
        <div className="row">
          {categories.map((c, i) =>
            <div key={i} className="col-lg-4 col-md-6 col-sm-6 text-center" style={{marginBottom: 30}}>
              <PopularCategory cat={c} />
            </div>
          )}
        </div>
      </section>
    );
  }
}
const mapStateToProps = state => ({app: state.app, cats: state.cats});
export const CategoriesHome = connect(mapStateToProps)(CategoriesHomeComponent);

export class PopularCategoryComponent extends Component {
  static propTypes = {
    config: PropTypes.any,
    url: PropTypes.any,
    cat: PropTypes.any.isRequired,
  }

  render() {
    const {cat} = this.props;

    return (
      <div>
        <div className="thumbnail">
          <img alt="" className="img-fluid" src={cat.url}/>
          <div className="caption">
            <CategoryLink cat={cat.category} className="text">
              {cat.category ? cat.category.name : ''}
              <i className="fa fa-angle-double-right" />
            </CategoryLink>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPropss = state => ({config: state.config});
export const PopularCategory = connect(mapStateToPropss)(PopularCategoryComponent);
