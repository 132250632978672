import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from '../../../util.js';

const maxPages = 10;

export class SearchPaginationComponent extends Component {

  static propTypes = {
    t: PropTypes.any.isRequired,
    currentPage: PropTypes.number,
    onClick: PropTypes.any.isRequired,
    pageAmount: PropTypes.any.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      page: props.currentPage || 0
    };
  }

  changePage(pagenumber, event) {
    this.setState({ page: pagenumber });
    this.props.onClick(event, pagenumber);
  }

  render() {
    let pages = [];
    let renderPages = [];
    let startPage = null;
    let endPage = null;

    const { t } = this.props;

    for (var i = 0; i < this.props.pageAmount; i++) {
      pages.push(i);
    }

    if (pages.length !== maxPages) {
      startPage = this.state.page - 3;
      endPage = this.state.page + 3;

      if (startPage < 0) {
        startPage = 0;
        endPage = endPage + (3 - this.state.page);
      }

      if (endPage > pages.length) {
        endPage = pages.length;

        if (startPage !== 0) {

          startPage = startPage - ((this.state.page + 3) - pages.length);
          if (startPage < 0) {
            startPage = 0;
          }
        }
      }

      renderPages = pages.slice(startPage, endPage);
    } else {
      renderPages = pages;
    }


    return (
      <div className="d-flex">
        <ul className="mx-auto pagination">
          {renderPages.length === 6 && startPage !== 0 && startPage !== null &&
            <li className="page-item" onClick={(e) => this.changePage(0, e)}>
              <a className="page-link" aria-label="Previous">
                <span aria-hidden="true">«</span>
                <span className="sr-only">{t('pagination.previous')}</span>
              </a>
            </li>
          }
          {renderPages.map((item) => (
            <li key={item} className={this.state.page === item ? 'page-item active' : 'page-item'} onClick={(e) => this.changePage(item, e)}>
              <a className="page-link">{item + 1}</a>
            </li>
          ))}
          {renderPages.length === 6 && endPage !== pages.length && endPage !== null &&
            <li className="page-item" onClick={(e) => this.changePage(pages.length - 1, e)}>
              <a className="page-link" aria-label="Next" >
                <span aria-hidden="true">»</span>
                <span className="sr-only">{t('pagination.next')}</span>
              </a>
            </li>
          }
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => ({ t: state.t });
export const SearchPagination = connect(mapStateToProps)(SearchPaginationComponent);