import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from '../../../util';

class PaymentMethodsComponent extends Component {
  static propTypes = {
    deliveryAvailable: PropTypes.any.isRequired,
    t: PropTypes.any.isRequired,
    config: PropTypes.any.isRequired,
    className: PropTypes.string,
  }

  render() {
    const {config, t, className, deliveryAvailable} = this.props;
    return (
      <div className={className}>
        <strong className="footer-title">{t('footer.paymentMethods')}</strong>
        <br/>
        <span>{t('footer.cash')}</span><br/>
        <span>{t('footer.bancontactOrCcreditCard', {acceptBancontactFrom: config.acceptBancontactFrom})}</span>
      </div>
    );
  }
}

export const Payment = connect(state => ({ deliveryAvailable: state.app.deliveryAvailable, config: state.config, t: state.t}))(PaymentMethodsComponent);
