import React, {Component} from 'react';
import {PropTypes} from '../../util.js';
import {SubCategoriesMenu} from '../category/SubCategoriesMenu.jsx';
import {SocialMediaIcons} from '../controls/SocialMediaIcons.jsx';

export class Menu extends Component {
  static propTypes = {
    category: PropTypes.CategoryModel.isRequired,
    children: PropTypes.any.isRequired,
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-3">
          <SubCategoriesMenu category={this.props.category} footer={<SocialMediaIcons className="social-media-icons-menu" />} />
        </div>
        <div className="col-md-9">
          {this.props.children}
        </div>
      </div>
    );
  }
}
