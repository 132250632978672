import DefaultPropTypes from 'prop-types';
import {allLanguages} from '../reducers/transReducer.js';
import {Category} from '../models/CategoryModel.js';
import {Product} from '../models/ProductModel.js';
import {CategoryHelper} from '../components/category/CategoryHelper.js';
import { SearchResult } from '../models/SearchResultModel.js';

export const PropTypes = Object.assign({}, DefaultPropTypes);

Object.assign(PropTypes, {
  lang: PropTypes.oneOf(allLanguages).isRequired,

  CategoryHelper: PropTypes.instanceOf(CategoryHelper),

  CategoryModel: PropTypes.instanceOf(Category),
  CategoryModelList: PropTypes.arrayOf(PropTypes.instanceOf(Category)),
  ProductModel: PropTypes.instanceOf(Product),
  ProductModelList: PropTypes.arrayOf(PropTypes.instanceOf(Product)),
  SearchResultModelList: PropTypes.arrayOf(PropTypes.instanceOf(SearchResult))
});
