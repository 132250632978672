import React, {Component} from 'react';
import {PropTypes} from '../../util.js';
import {Link} from 'react-router-dom';

export class ProductLink extends Component {
  static propTypes = {
    prod: PropTypes.ProductModel.isRequired,
    children: PropTypes.any,
  }

  render() {
    const {prod, children, ...props} = this.props;
    return (
      <Link to={prod.slug} {...props} onClick={() => window.scrollTo(0, 0)}>{children || prod.name}</Link>
    );
  }
}
