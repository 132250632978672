import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from '../../util.js';
import {Icon} from './Icon.jsx';

class FacebookIconComponent extends Component {
  static propTypes = {
    facebook: PropTypes.string.isRequired,
  }

  render() {
    const {facebook} = this.props;
    return (
      <a href={facebook} target="_blank" rel="noopener noreferrer">
        <Icon fa="fa-facebook fa-2x" />
      </a>
    );
  }
}

const mapStateToProps = state => ({facebook: state.config.socialMedia.facebook});
export const FacebookIcon = connect(mapStateToProps)(FacebookIconComponent);
