import React, {Component} from 'react';
import {PropTypes} from '../../util.js';
import noProdImg from '../../img/no-prod-img.png';

export class ProductImage extends Component {
  static propTypes = {
    prod: PropTypes.ProductModel.isRequired,
    imageIndex: PropTypes.number,
  }

  static defaultProps = {
    imageIndex: 1,
  }

  render() {
    const {prod, imageIndex, ...props} = this.props;
    const imgSrc = prod.getImageUrl(imageIndex);
    if (!imgSrc) {
      return <img src={noProdImg} alt="no-prod-img" {...props} />;
    }

    return <img src={imgSrc.path} alt="" {...props} />;
  }
}
