import {ActionTypes} from './ActionTypes.js';


export function changeViewport() {
  return {type: ActionTypes.VIEWPORT_RESIZE, payload: null};
}



export function setLanguage(lang = null) {
  return {type: ActionTypes.SET_LANGUAGE, payload: lang};
}



export function toggleMobileMenu() {
  return {type: ActionTypes.TOGGLE_MENU};
}



export function setCategorySortBy(sortKey) {
  return {type: ActionTypes.CATEGORY_SORT_CHANGED, payload: sortKey};
}

export function setCategoryPageSize(pageSize) {
  return {type: ActionTypes.CATEGORY_PAGESIZE_CHANGED, payload: pageSize};
}
