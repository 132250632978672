import React from 'react';
import { useSelector } from 'react-redux';
import {Routes} from './Routes';
import {useGaTracker} from './util/googleAnalytics.js';
import {Footer} from './components/skeleton/Footer.jsx';
import {Header} from './components/skeleton/Header.jsx';

export {default as cn} from 'classnames';

function App() {
  const [ga, app] = useSelector((state) => [state.config.ga, state.app]);

  useGaTracker(ga);

  return (
    <div>
      <div className={app.deviceSameOrLargerThan('xxl') ? 'container-fluid' : 'container-fluid'} style={{minHeight: 300}}>
        <Header />
        {Routes}
      </div>
      <Footer />
    </div>
  );
}

export default App;
