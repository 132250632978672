import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes, CategoryHelper} from '../util.js';
import {Icon, CategoryLink, SubCategoriesTagCloud} from './controls.js';

class CatalogPageComponent extends Component {
  static propTypes = {
    config: PropTypes.any,
    cats: PropTypes.CategoryModelList.isRequired,
    prods: PropTypes.ProductModelList.isRequired,
  }

  render() {
    const cats = new CategoryHelper(this.props.cats, this.props.prods);
    return (
      <div className="catalog-page">
        {cats.getMain().map(cat => [
          <div key={cat.id} className="main-cat">
            <CategoryLink cat={cat} className="main-cat-link">
              <Icon fa="fa-angle-double-right" />
              {cat.name}
            </CategoryLink>
          </div>,
          <SubCategoriesTagCloud key={cat.id + '_subs'} category={cat} />
        ])}
      </div>
    );
  }
}

const mapStateToProps = state => ({config: state.config, cats: state.cats, prods: state.prods});
export const CatalogPage = connect(mapStateToProps)(CatalogPageComponent);
