import React from 'react';
import {Component} from 'react';
import {FacebookIcon, TwitterIcon} from '../controls.js';


export class SocialMediaIcons extends Component {
  render() {
    const {...props} = this.props;

    return (
      <div className="social-media-icons" {...props}>
        <FacebookIcon/>
        <TwitterIcon/>
      </div>
    );
  }
}
