import React, {Component} from 'react';
import {ProductsHome} from './ProductsHome.jsx';
import {CategoriesHome} from './CategoriesHome.jsx';
import {CarouselHome} from './CarouselHome';

export class Home extends Component {
  render() {
    return (
      <div>
        <CarouselHome />
        <CategoriesHome />
        <ProductsHome />
      </div>
    );
  }
}
