import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from '../../../util';
import {PrivacyStatementEn} from './PrivacyStatement-en';
import {PrivacyStatementNl} from './PrivacyStatement-nl';
import {PrivacyStatementFr} from './PrivacyStatement-fr';

const gdprEmail = 'info@stockoma.be';
const registeredOffice = 'Brusselsesteenweg 339. 1730 Kobbegem';


class PrivacyStatementComponent extends Component {
  static propTypes = {
    lang: PropTypes.string.isRequired,
  }

  render() {
    const {lang} = this.props;

    let PrivacyStatementComp;
    switch (lang) {
    case 'nl':
      PrivacyStatementComp = PrivacyStatementNl;
      break;
    case 'fr':
      PrivacyStatementComp = PrivacyStatementFr;
      break;
    default:
      PrivacyStatementComp = PrivacyStatementEn;
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <PrivacyStatementComp gdprEmail={gdprEmail} registeredOffice={registeredOffice} />
        </div>
      </div>
    );
  }
}

export const PrivacyStatement = connect(state => ({lang: state.t.lang}))(PrivacyStatementComponent);
