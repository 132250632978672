import keyMirror from 'keymirror';

export const ActionTypes = keyMirror({
  INITIAL_LOAD: '',
  SET_LANGUAGE: '',
  VIEWPORT_RESIZE: '',
  TOGGLE_MENU: '',
  CONFIG_FETCHED: '',

  /* Categories action types */
  CATEGORIES_FETCHED: '',
  CATEGORY_SORT_CHANGED: '',
  CATEGORY_PAGESIZE_CHANGED: '',

  /* Products action types */
  PRODUCTS_FETCHED: '',
  PRODUCTS_VIEWS_FETCHED: '',
});
