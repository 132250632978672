import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from '../../util.js';
import {Icon} from './Icon.jsx';

class LinkedInIconComponent extends Component {
  static propTypes = {
    linkedIn: PropTypes.string.isRequired,
  }

  render() {
    const {linkedIn} = this.props;
    return (
      <a href={linkedIn} target="_blank" rel="noopener noreferrer">
        <Icon fa="fa-linkedin fa-2x" />
      </a>
    );
  }
}

const mapStateToProps = state => ({linkedIn: state.config.socialMedia.linkedIn});
export const LinkedInIcon = connect(mapStateToProps)(LinkedInIconComponent);
