import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from '../../../util.js';
import {Icon} from '../../controls.js';

export const MailUsIcon = connect(state => ({ourEmail: state.config.contact.email, t: state.t}))(class extends Component {
  static propTypes = {
    ourEmail: PropTypes.string.isRequired,
    t: PropTypes.any.isRequired,
  }
  render() {
    return (
      <a href={'mailto:' + this.props.ourEmail} className="nav-link" title={this.props.t('nav.emailUs')}>
        <Icon fa="fa-fw fa-envelope-square" />
      </a>
    );
  }
});
