import { SearchResult } from '../models/SearchResultModel';
import latinize from 'latinize';

export const ApplySearch = (SearchResults: SearchResult[], searchTerm: string) => {
  var results = [];
  if (/\S/.test(searchTerm)) {
    var search = searchTerm.toLocaleLowerCase();
    results = SearchText(SearchResults, 'title', search, (items) => {
      return SearchText(items, 'desc', search);
    });
  }
  return results;
};

function SearchText(items, field, searchQuery, linkFunction) {
  var linked = linkFunction !== undefined;
  var starts = [];
  var contains = [];
  var others = [];
  for (var i = 0; i < items.length; i++) {
    var itemField = items[i][field];
    if (itemField !== undefined) {
      var searchText = itemField.get().toLowerCase();
      var altSearchText = latinize(searchText);
      if (searchText.includes(searchQuery) || altSearchText.includes(searchQuery)) {
        if (searchText.indexOf(searchQuery) === 0 || altSearchText.indexOf(searchQuery) === 0) {
          starts.push(items[i]);
        } else {
          contains.push(items[i]);
        }
      } else if (linked) {
        others.push(items[i]);
      }
    }
  }
  starts.sort();
  contains.sort(); var results = starts.concat(contains);
  if (linked) {
    others = linkFunction(others);
    others.sort();
    results = results.concat(others);
  }
  return results;
}