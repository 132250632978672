import React, {Component} from 'react';
import {PropTypes, connect} from '../../../util.js';

class ProductDetailNoticesComponent extends Component {
  static propTypes = {
    t: PropTypes.any.isRequired,
    className: PropTypes.string,
  }

  render() {
    const t = this.props.t;
    return (
      <div className={this.props.className}>
        <h4>{t('product.notices')}</h4>
        <ul>
          <li>{t('product.pricesMayVary')}</li>
          <li>{t('product.mayNotBeAvailable')}</li>
          <li>{t('product.mayNotBeAvailable2')}</li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => ({t: state.t});
export const ProductDetailNotices = connect(mapStateToProps)((ProductDetailNoticesComponent));
