import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from '../../../util.js';

class OpeningHoursComponent extends Component {
  static propTypes = {
    t: PropTypes.any.isRequired,
    hours: PropTypes.any.isRequired,
    newHours: PropTypes.any.isRequired,
    className: PropTypes.string,
  }

  render() {
    const {hours, newHours, t, className} = this.props;

    let today = new Date();
    let openingHours = today < new Date('2022/05/01') ? hours : newHours;

    return (
      <div className={className}>
        <strong className="footer-title">{t('openingHours.openingHours')}</strong>
        <DayOpeningHours day="monday" hours={openingHours.monday}/>
        <DayOpeningHours day="tuesday" hours={openingHours.tuesday}/>
        <DayOpeningHours day="wednesday" hours={openingHours.wednesday}/>
        <DayOpeningHours day="thursday" hours={openingHours.thursday}/>
        <DayOpeningHours day="friday" hours={openingHours.friday}/>
        <DayOpeningHours day="saturday" hours={openingHours.saturday}/>
        <DayOpeningHours day="sunday" hours={openingHours.sunday}/>
      </div>
    );
  }
}

export const OpeningHours = connect(state =>
  ({t: state.t, hours: state.config.openingHours, newHours: state.config.newOpeningHours}))(OpeningHoursComponent);

class DayOpeningHoursComponent extends Component {
  static propTypes = {
    t: PropTypes.any.isRequired,
    hours: PropTypes.any,
    day: PropTypes.string.isRequired,
  }

  render() {
    const {hours, t, day} = this.props;
    if (!hours || !hours.length) {
      return (
        <div>
          <Day text={t('openingHours.' + day)} />
          <span className="text-uppercase">{t('openingHours.closed')}</span>
        </div>
      );
    }

    const hoursBeforeNoon = hours[0];
    const hoursAfterNoon = hours.length > 1 ? hours[1] : null;
    return (
      <div>
        <Day text={t('openingHours.' + day)} />
        {hoursBeforeNoon[0]}-{hoursBeforeNoon[1]}
        {hoursAfterNoon ? (
          <span>
            ,
            &nbsp;
            {hoursAfterNoon[0]}-{hoursAfterNoon[1]}
          </span>
        ) : null}
      </div>
    );
  }
}

const DayOpeningHours = connect(state => ({t: state.t}))(DayOpeningHoursComponent);

const Day = ({text}) => {
  return <span style={{display: 'inline-block', width: 100}}>{text}:</span>;
};

Day.propTypes = { text: PropTypes.any.isRequired };
