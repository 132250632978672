import React, {Component} from 'react';
import {PropTypes, connect} from '../../../util.js';
import {ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

const getProductSortOptions = () => [
  {key: 'popularity-desc', text: 'catToolbar.popularity'},
  {key: 'name-asc', text: 'catToolbar.sortNameAsc'},
  {key: 'name-desc', text: 'catToolbar.sortNameDesc'},
  {key: 'price-desc', text: 'catToolbar.sortPriceDesc'},
  {key: 'price-asc', text: 'catToolbar.sortPriceAsc'},
];


class SortBySelectorComponent extends Component {
  static propTypes = {
    t: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    dispatch: PropTypes.any,
  }

  constructor() {
    super();
    this.state = {isOpen: false};
  }

  render() {
    const {onChange, value, t, dispatch, ...props} = this.props; // eslint-disable-line no-unused-vars
    const sortingOptions = getProductSortOptions();
    const selectedSort = sortingOptions.find(sort => sort.key === value);
    return (
      <div {...props}>
        <span style={{marginRight: 6}}>{t('search.sortBy')}</span>
        <ButtonDropdown isOpen={this.state.isOpen} toggle={() => this.setState({isOpen: !this.state.isOpen})}>
          <DropdownToggle caret size="sm" color="dropdown">
            {t(selectedSort.text)}
          </DropdownToggle>
          <DropdownMenu>
            {sortingOptions.map(sort => (
              <DropdownItem key={sort.key} onClick={() => onChange(sort.key)}>
                {t(sort.text)}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    );
  }
}

export const SortBySelector = connect(state => ({t: state.t}))(SortBySelectorComponent);
