import {Category} from '../../models/CategoryModel.js';
import {Product} from '../../models/ProductModel.js';
import {Translation} from '../../models/TranslationModel.js';

function convertToUsSize(euroSizeTrans: ?Translation): Array<Translation> {
  if (!euroSizeTrans) {
    return [];
  }

  // https://www.healthyfeetstore.com/shoe-length-sizing-charts.html
  // ATTN: These are MEN convertion rates. Women shoes are different
  const convertionTable = {
    '39': ['6', '6.5'],
    '40': ['7', '7.5'],
    '41': ['8', '8.5'],
    '42': ['8.5', '9'],
    '43': ['9.5', '10'],
    '44': ['10', '10.5', '11'],
    '45': ['11.5'],
    '46': ['12'],
    '47': ['13'],
    '48': ['14'],
    '49': ['15'],
    '50': ['16']
  };

  const euroSize = parseInt(euroSizeTrans.get(), 10);
  const usSizes = convertionTable[euroSize];
  if (!usSizes) {
    return [];
  }

  return usSizes.map(x => new Translation({en: x}));
}

export class CategoryHelper {
  categories: Array<Category>;
  products: Array<Product>;

  constructor(cats: Array<Category>, prods: Array<Product>) {
    this.categories = cats;
    this.products = prods;
  }

  getParent(category: Category): ?Category {
    if (category.mainCategory) {
      return category;
    }
    return this.categories.find(cat => cat._categories.includes(category.id));
  }

  getMain(): Array<Category> {
    return this.categories.filter(c => c.mainCategory).sort((a, b) => a.name.localeCompare(b.name));
  }

  getSubCats(category: Category, includeSelf: bool = false): Array<Category> {
    const cats = [];
    if (includeSelf) {
      cats.push(category);
    }

    category.categories.forEach(subCat => {
      cats.push(subCat);
      cats.push.apply(cats, this.getSubCats(subCat, false));
    });

    return cats;
  }

  getWithViewCount(category: Category): Array<{category: Category, views: number}> {
    if (!category.categories.length) {
      return [];
    }

    const subCats = this.getSubCats(category);

    const viewPerCategory = subCats.reduce((acc, next) => {
      const prodsInCat = this.products.filter(p => p.categories.includes(next.id));
      acc.push({
        category: next,
        views: prodsInCat.map(p => p.views).reduce((totalViews, prodViews) => totalViews + prodViews, 0),
        //prods: prodsInCat.length,
      });
      return acc;
    }, []);

    return viewPerCategory.sort((a, b) => a.category.name.localeCompare(b.category.name));
  }

  getPopular(category: Category, take: number = 5): Array<Category> {
    var result = this.getWithViewCount(category);
    if (take) {
      result = result.slice(0, take);
    }
    return result.map(res => res.category);
  }

  getSpecs(product: Product) {
    var allSpecs = [];

    const noSpecsCat = this.categories.find(cat => cat.name === 'SPECS WITHOUT CATEGORY');
    var catsWithSpecs = noSpecsCat ? [noSpecsCat] : [];
    catsWithSpecs = catsWithSpecs.concat(product.categories.map(catId => this.categories.find(c => c.id === catId)));

    if (product.specs && product.specs.length) {
      const specs = product.specs.reduce((acc, prodSpec) => {
        for (let catSpecs of catsWithSpecs) {
          const specDetails = catSpecs.specs.find(s => s.id === prodSpec.specId);
          if (specDetails) {
            const specDetailsInfo = new ProductSpecDetails({
              name: specDetails.name,
              options: prodSpec.options.map(option => {
                const optionDetails = specDetails.specOptions.find(so => so.id === option);
                return optionDetails ? optionDetails.name : null;
              })
            });

            if (specDetails.type === 'SHOES-EUR') {
              const usSizes = new ProductSpecDetails({
                name: specDetails.name.replace('Euro', 'US'),
                options: prodSpec.options.reduce((relevantOptions, optionId: string) => {
                  const optionDetails = specDetails.specOptions.find(so => so.id === optionId);
                  if (!optionDetails) {
                    return relevantOptions;
                  }
                  const newOptions = convertToUsSize(optionDetails.name).filter(option => relevantOptions.every(opt => opt.get() !== option.get()));
                  return relevantOptions.concat(newOptions);
                }, [])
              });
              return acc.concat([specDetailsInfo, usSizes]);
            }
            return acc.concat([specDetailsInfo]);
          }
        }

        console.error('!!!!!! ATTN !!!!!!!!!!! PROD SPECS NOT FOUND', product); // eslint-disable-line no-console
        return acc;
      }, []);

      allSpecs = allSpecs.concat(specs);
    }

    if (product.options && product.options.length) {
      const options = product.options.map(optionId => {
        for (let catSpecs of catsWithSpecs) {
          const optionDetails = catSpecs.options.find(o => o.id === optionId);
          if (optionDetails) {
            return new ProductSpecDetails({
              name: optionDetails.title,
              options: [optionDetails.name],
            });
          }
        }

        console.error('!!!!!! ATTN !!!!!!!!!!! PROD OPTIONS NOT FOUND', product); // eslint-disable-line no-console
        return null;
      });

      allSpecs = allSpecs.concat(options);
    }

    return allSpecs.filter(spec => !!spec);
  }
}

class ProductSpecDetails {
  _name: Translation;
  _options: Array<Translation>;

  get name(): string {
    return this._name.get();
  }
  get options(): Array<string> {
    return this._options.map(option => option.get());
  }

  constructor(json: any) {
    this._name = new Translation(json.name);
    this._options = json.options.map(option => new Translation(option));
  }
}
