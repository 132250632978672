import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import {Spinner} from '../Spinner.jsx';
import {NoMatch} from '../../skeleton/NoMatch.jsx';

const mapStateToProps = state => ({prods: state.prods, cats: state.cats});
export const ProductIdResolverDecorator = ComposedComponent => connect(mapStateToProps)(
  class ProductIdResolverComponent extends Component {
    static propTypes = {
      match: PropTypes.shape({
        params: PropTypes.shape({
          slug: PropTypes.string.isRequired,
          prodId: PropTypes.string.isRequired,
          catId: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      prods: PropTypes.array.isRequired,
      cats: PropTypes.array.isRequired,
    }

    render() {
      const {match, prods, ...props} = this.props;
      if (prods.length === 0 || this.props.cats.length === 0) {
        return <Spinner />;
      }

      const productId = match.params.prodId;
      const product = prods.find(prod => prod.id === productId);

      const categoryId = match.params.catId;
      const category = this.props.cats.find(c => c.id === categoryId);

      if (!product || !category) {
        return <NoMatch msg="notFound.prodMsg" />;
      }

      return <ComposedComponent product={product} category={category} {...props} />;
    }
  }
);

export const ProductIdResolver = ProductIdResolverDecorator;
