export const trans = {
  facebookLanguage: 'en_US',
  title: 'Stockoma',
  categories: 'Categories',
  catalog: 'Catalog',
  corona: {
    order: 'Order',
    mailUs: 'Mail us your wishlist and have your goodies delivered at your home or come pick them up!',

    title: 'Order from our catalog',
    wayOfWorking: 'Send us your order via email and we\'ll confirm the availability of the goods as soon as possible.',
    wayOfWorking2: 'Payment and shipping options will be communicated as your order comes in.',
    pickupTitle: 'Pickup service',
    pickup: 'We prepare your packet and let you know when you can come pick it up at our store.',
    deliveryTitle: 'Delivery service',
    delivery: 'All deliveries with GLS: €8,5 shipping costs in Belgium.',
    noWeapons: 'Attention! Airguns, weapons etc cannot be ordered.',
    footer: 'or... use our',
  },
  corona_banner:{
    title: 'Corona virus measures!',
    paragraphOne: 'From 27/03 up to and inlcuding 26/04, new opening hours',
    paragraphTwo: '(FROM 10.00 - 12.00 AND FROM 13.30 tot 18.00) by appointment, no purchase is necessary, feel free to visit us for the atmosphere!',
    paragraphThree: 'You don\'t have an appointment? Then we will register you at the entrance. You may have to wait 5 minutes before you are granted access to the store.', // eslint-disable-line max-len
    paragraphFour: 'An appointment can be made by e-mail or by telephone. You specify date, time.',
    paragraphFive: 'See you soon and thank you for shopping with us!'
  },
  vacation_banner:{
    title:'We\'re taking a break',
    message: 'We are closed from 25/10 until 29/10'
  },
  nav: {
    contact: 'Contact',
    allCats: 'All products',
    callUs: 'Call us',
    emailUs: 'Email us',
    contactUs: 'Contact us',
  },
  product: {
    notices: 'Attention!',
    pricesMayVary: 'Actual prices in store may vary.',
    mayNotBeAvailable: 'Please contact us if you want to be sure a product is in stock.',
    mayNotBeAvailable2: 'If you can\'t find an article or size in the store, ask one of our staff members about it.',
    description: 'Description',
    specifications: 'Specifications',
    morePicture: '1 more picture',
    morePictures: '{cnt} more pictures',
  },
  category: {
    subcatFilter: 'Filter',
    openSubcatFilters: 'Show filters',
  },
  footer: {
    address: 'Address',
    contact: 'Contact',
    addressInstructions: 'Brussels Ring Exit 10 Asse',
    copyright: '{years} Stockoma',
    cookieNotice: 'We use cookies to give you the best experience possible. By continuing to browse the site you are agreeing to our use of cookies.', // eslint-disable-line
    cookieNoticeClose: 'Close',
    paymentMethods: 'Payment methods',
    cash: 'Cash',
    bancontactOrCcreditCard: 'Bancontact or Credit Card from €{acceptBancontactFrom}',
    connect: 'Stay connected',
    spotlight: 'In the spotlight',
    weaponLicenseNumber: 'Weapon license number',
    vatNumber: 'VAT number',
    privacyStatement: 'Privacy statement',
    cookiePolicy: 'Cookie Policy',
  },
  openingHours: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    thursday: 'Thursday',
    wednesday: 'Wednesday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    closed: 'Closed',
    openingHours: 'Opening hours',
    newopeningHours: 'New openinghours starting may'
  },
  catToolbar: {
    popularity: 'Popularity',
    sortPriceAsc: 'Price (Low > High)',
    sortPriceDesc: 'Price (High > Low)',
    sortNameAsc: 'Name (A > Z)',
    sortNameDesc: 'Name (Z > A)',
  },
  search: {
    search: 'Search',
    sortBy: 'Sort By',
    pageSize: 'Show',
    pageTitle: 'Search results',
    category: 'Category',
    product: 'Product',
    moreResults: 'More results'
  },
  pagination: {
    next: 'Next',
    previous: 'Previous'
  },
  notFound: {
    title: '404',
    msg: 'This page could not be found!',
    prodMsg: 'This product could not be found!',
    catMsg: 'This category could not be found!',
    catalogInstead: 'Browse our catalog instead',
  },
};
