import {store} from './store.js';
import {initialLoad, changeViewport} from '../actions/index.js';

import '../styles/index.scss';
import 'font-awesome/css/font-awesome.css';

// Fetch data from the db
store.dispatch(initialLoad());

window.addEventListener('resize', () => {
  store.dispatch(changeViewport());
});
