import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from '../../../util.js';
import {FacebookProvider, Like} from 'react-facebook';

export const HeaderFacebookIcon = connect(state => ({
  facebook: state.config.socialMedia.facebook,
  facebookAppId: state.config.socialMedia.facebookAppId,
  t: state.t,
  app: state.app,
}))(class extends Component {
  static propTypes = {
    facebook: PropTypes.string.isRequired,
    facebookAppId: PropTypes.string.isRequired,
    t: PropTypes.any.isRequired,
    app: PropTypes.any.isRequired,
  }

  render() {
    const app = this.props.app;
    const showFaces = app.deviceSameOrLargerThan('lg');

    const config = {
      xxl: {width: 350, size: 'large', noShare: false},
      xl: {width: 350, size: 'small', noShare: true},
      lg: {width: 200, size: 'small', noShare: true},
      md: {width: 50, layout: 'box_count', noShare: true, size: 'small', language: 'en_US'},
      sm: {width: 50, layout: 'box_count', noShare: true, size: 'small', language: 'en_US'},
      xs: {width: 50, layout: 'box_count', noShare: true, size: 'small', language: 'en_US'},
    };

    const activeConfig = config[app.device];

    return (
      <FacebookProvider appId={this.props.facebookAppId} language={activeConfig.language || this.props.t('facebookLanguage')}>
        <Like
          href={this.props.facebook}
          colorScheme="dark"
          showFaces={showFaces}
          share={!activeConfig.noShare}
          width={activeConfig.width}
          size={activeConfig.size || 'large'}
          layout={activeConfig.layout || 'standard' }
        />
      </FacebookProvider>
    );
  }
});
