import React, {Component} from 'react';
import {PropTypes, connect} from '../../../util.js';
import {ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

// TODO: this is no good (too many sub cats!). REMOVE SOMETIME

class SubCategoriesSelectorComponent extends Component {
  static propTypes = {
    mainCat: PropTypes.CategoryModel.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.CategoryModel.isRequired,
    t: PropTypes.any.isRequired,
  }

  constructor() {
    super();
    this.state = {isOpen: false};
  }

  render() {
    const {onChange, value, t} = this.props;
    const subCats = this.props.mainCat.categories.sort((a, b) => a.name.localeCompare(b.name));
    return (
      <div>
        <span style={{marginRight: 6}}>{t('category.subcatFilter')}</span>
        <ButtonDropdown isOpen={this.state.isOpen} toggle={() => this.setState({isOpen: !this.state.isOpen})}>
          <DropdownToggle caret size="sm" color="dropdown">
            {value}
          </DropdownToggle>
          <DropdownMenu>
            {subCats.map(cat => (
              <DropdownItem key={cat.id} onClick={() => onChange(cat)}>
                {cat.name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    );
  }
}

export const SubCategoriesSelector = connect(state => ({t: state.t}))(SubCategoriesSelectorComponent);
