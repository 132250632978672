import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from '../src/util/store.js';
import App from './App.js';
import {setLanguage} from '../src/actions/appActions.js';

import './util/startup.js';

store.dispatch(setLanguage());

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);
