import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {PropTypes, CategoryHelper} from '../../../util.js';
import {Collapse, NavbarToggler, Nav, NavItem, NavLink} from 'reactstrap';
import {toggleMobileMenu} from '../../../actions/appActions.js';


class MobileMenuComponent extends Component {
  static propTypes = {
    t: PropTypes.any.isRequired,
    cats: PropTypes.CategoryModelList.isRequired,
    prods: PropTypes.ProductModelList.isRequired,
    device: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']).isRequired,
    mobileMenuOpen: PropTypes.bool.isRequired,
    toggleMobileMenu: PropTypes.func.isRequired,
  }

  render() {
    const {device, mobileMenuOpen, t} = this.props;
    if (device !== 'xs' && device !== 'sm') {
      return null;
    }

    const cats = new CategoryHelper(this.props.cats, this.props.prods);
    return [
      <NavbarToggler key="collapse-icon" className="clickable" onClick={() => this.props.toggleMobileMenu()} />,
      <Collapse key="collapse-menu" isOpen={mobileMenuOpen} navbar>
        <Nav navbar>
          {cats.getMain().map(cat => (
            <MobileMenuLink key={cat.id} to={cat.slug} text={cat.name} />
          ))}
          <MobileMenuLink to="/catalog" text={t('nav.allCats')} />
        </Nav>
      </Collapse>
    ];
  }
}

const mapStateToProps = state => ({
  prods: state.prods,
  cats: state.cats,
  device: state.app.device,
  mobileMenuOpen: state.app.mobileMenuOpen,
  t: state.t
});
export const MobileMenu = connect(mapStateToProps, {toggleMobileMenu})(MobileMenuComponent);



const MobileMenuLink = connect(null, {toggleMobileMenu})(class extends Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    children: PropTypes.any,
    toggleMobileMenu: PropTypes.func.isRequired,
  }

  render() {
    const {to, text, children} = this.props;
    return (
      <NavItem>
        <NavLink tag={Link} to={to} onClick={() => this.props.toggleMobileMenu()}>{text}</NavLink>
        {children}
      </NavItem>
    );
  }
});
