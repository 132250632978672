import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from '../../util.js';
import {CategoryPageToolbar, CollapsableCategoryPageToolbar} from './CategoryPage/CategoryPageToolbar.jsx';
import {CategoryPageProductList} from './CategoryPage/CategoryPageProductList.jsx';
import {CategoryIdResolverDecorator} from '../controls.js';
import {Menu} from '../skeleton/Menu.jsx';

class CategoryPageComponent extends Component {
  static propTypes = {
    device: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']).isRequired,
    category: PropTypes.CategoryModel.isRequired,
    page: PropTypes.number.isRequired,
  }

  render() {
    const {device, category} = this.props;
    if (device !== 'xs' && device !== 'sm') {
      return (
        <Menu category={category}>
          <CategoryPageToolbar />
          <CategoryPageProductList category={category} page={this.props.page} />
        </Menu>
      );
    }

    return (
      <div className="category-page-xs">
        <CollapsableCategoryPageToolbar category={category} />
        <h1>{category.name}</h1>
        <CategoryPageProductList category={category} page={this.props.page} />
      </div>
    );
  }
}

export const CategoryPage = connect(state => ({device: state.app.device}))(CategoryIdResolverDecorator(CategoryPageComponent));
