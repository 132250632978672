import React from 'react';
import PropTypes from 'prop-types';

export const PrivacyStatementFr = ({gdprEmail, registeredOffice}) => (
  <div>
    <h1>Déclaration de confidentialité</h1>

    <h2>Résumé de la Déclaration de confidentialité</h2>

    <p>Cette déclaration de confidentialité explique quelles informations nous collectons à votre
    sujet, les objectifs pour lesquels nous les utilisons et à qui nous les transmettons. Elle spécifie
    également vos droits et qui vous pouvez contacter pour obtenir de plus amples informations
    ou pour adresser des demandes de renseignements.</p>

    <p>Votre information sera utilisé conformément aux autorisations que vous avez accordées, à la
    loi du 30 juillet 2018 à la protection des personnes physiques à l’égard des traitements de
    données à caractère personnel et au Règlement (UE) 2016/679 du Parlement européen et du
    Conseil du 27 avril 2016 (entré en vigueur le 25 mai 2018) relatif à la protection des
    personnes physiques à l’égard du traitement des données à caractère personnel et à la libre
    circulation de ces données (RGPD).Ce Privacy Notice est également mis à votre disposition
    pour vous aider à prendre des décisions éclairées dans le cadre de l’utilisation de notre site
    Web.</p>


    <h2>À qui s’applique cette déclaration de confidentialité et que couvre-t-elle ?</h2>

    <p>Cette déclaration de confidentialité présente la manière dont nous collectons, traitons,
    stockons et protégeons les informations à votre sujet lorsque :</p>

    <ul>
      <li>nous vous fournissons des services ou nous fournissons des services à nos clients ;</li>
      <li>vous utilisez « notre Site Internet », ou</li>
      <li>nous réalisons toute autre activité dans le cadre du fonctionnement de notre entreprise, tel que décrit plus en détail ci-dessous.</li>
    </ul>

    <p>Dans le cadre de cette politique, lorsque nous faisons référence à « notre Site Internet » ou à «
    ce site Internet », nous entendons la page de Stockoma.be.</p>

    <p>Stockoma.be se compose de divers sites Internet à l’échelle mondiale, nationale, régionale ou
    propres à un bureau spécifique, chacun étant fourni par DTTL ou l’un de ses cabinets
    membres indépendants ou leurs entités liées (collectivement, le « Réseau Stockoma »).</p>

    <p>Cette déclaration de confidentialité contient également des informations précisant à quel
    moment nous partageons vos données à caractère personnel avec d’autres membres du Réseau
    Stockoma et d’autres tiers (par exemple, nos fournisseurs de services).</p>

    <p>Dans cette déclaration de confidentialité, vos informations sont parfois appelées « données à
    caractère personnel » ou « informations à caractère personnel ». Nous pouvons également
    désigner collectivement le traitement, la collecte, la protection et le stockage de vos
    informations à caractère personnel sous le terme de « traitement » de ces informations à
    caractère personnel.</p>


    <h2>Quelles informations collectons-nous ?</h2>
    <p>Dans le cadre des services que nous vous fournissons ou que nous fournissons à nos clients et
    dans le cadre de la réalisation de contrôles de due diligence liés à nos services (ou de
    discussions sur les services que nous pourrions éventuellement fournir), nous collecterons ou
    obtiendrons des données à caractère personnel à votre sujet. Nous pouvons également
    collecter vos données à caractère personnel lorsque vous utilisez notre site Internet.</p>

    <p>Nous pouvons collecter ou obtenir ces données parce que vous nous les fournissez (par
    exemple, dans un formulaire sur notre Site Internet, parce que d’autres personnes nous les
    fournissent (par exemple, votre employeur ou votre conseiller ou des fournisseurs de services
    tiers que nous utilisons pour faire fonctionner notre entreprise) ou parce qu’elles sont
    disponibles dans le domaine public.</p>

    <p>Nous pouvons collecter ou obtenir des données à caractère personnel de votre part parce que
    nous déterminons ou extrapolons ces données vous concernant à partir de la manière dont
    vous interagissez avec nous ou avec d’autres. Par exemple, pour améliorer votre expérience
    lorsque vous utilisez notre Site Internet et pour nous assurer de son fonctionnement effectif,
    nous (ou nos fournisseurs de services) pouvons utiliser des cookies (de petits fichiers texte
    stockés dans le navigateur de l’utilisateur) et des pixels-espions qui peuvent collecter des
    données à caractère personnel. Des informations complémentaires sur la manière dont nous
    utilisons les cookies et d’autres technologies de traçage ainsi que sur la manière dont vous
    pouvez les contrôler peuvent être consultées dans notre politique en matière de cookies.</p>

    <p>Les données à caractère personnel que nous collectons ou obtenons peuvent inclure : votre
    nom, votre âge, votre date de naissance, votre sexe, votre adresse e-mail, votre adresse
    postale, votre pays de résidence, votre mode de vie et votre situation sociale (par exemple, vos
    passe-temps), votre situation familiale (par exemple, votre état civil et les personnes à votre
    charge), des détails sur votre emploi et votre formation (par exemple, l’entreprise pour
    laquelle vous travaillez, le poste que vous occupez et votre formation), des informations
    financières et fiscales (par exemple, votre revenu et votre résidence fiscale), vos diffusions sur
    des blogs, forums, wikis et autres applications et services de médias sociaux que nous
    fournissons, votre adresse IP, le type et la langue de votre navigateur, les heures de vos
    visites, des détails sur vos réclamations, des détails sur la manière dont vous utilisez nos
    produits et services, des détails sur la manière dont vous préférez interagir avec nous, et
    d’autres informations similaires.</p>

    <p>Les données à caractère personnel que nous collectons peuvent également inclure des données
    à caractère personnel dites « sensibles » ou des « catégories particulières » de données à
    caractère personnel, notamment des détails concernant : votre régime alimentaire (par
    exemple, si Stockoma souhaite vous proposer un repas pendant une réunion), votre santé (par
    exemple, afin que nous puissions procéder à des aménagements raisonnables de nos
    immeubles, produits et services) et votre orientation sexuelle (par exemple, si vous nous
    fournissez des détails sur votre conjoint(e) ou votre partenaire).</p>

    <p>Les types de données à caractère personnel et de catégories particulières de données à
    caractère personnel que nous collectons peuvent varier selon la nature des services que nous
    vous fournissons ou que nous fournissons à nos clients ou l’utilisation que vous faites de notre
    Site Internet. Dans de rares cas, nous pourrons également réunir d’autres catégories
    particulières de données à caractère personnel, soit parce que vous nous les communiquez
    volontairement, soit parce que nous sommes tenus de les recueillir en raison d’exigences
    légales qui nous sont imposées.</p>

    <p>Si notre client nous fournit des données à caractère personnel vous concernant, nous
    déploierons des efforts raisonnables pour inclure dans le contrat que nous concluons avec ce
    client des clauses lui imposant de respecter les lois et réglementations en matière de vie privée
    applicables à ces informations ; ce sera le cas, par exemple, si le client vous a avisé de la
    collecte de ces informations (et d’autres questions) et a obtenu les consentements nécessaires
    nous autorisant à traiter ces informations selon les modalités décrites dans cette déclaration de
    confidentialité.</p>

    <p>Nous comprenons l’importance de la protection de la vie privée des enfants. Notre Site
    Internet et nos services ne sont pas conçus pour les enfants et ne s’adressent pas
    intentionnellement à eux. Notre politique ne vise pas à collecter ou à stocker
    intentionnellement des informations sur des enfants.</p>


    <h2>Comment utilisons-nous les informations à votre sujet ?</h2>
    <h3>Utilisation d’informations à caractère personnel pour fournir des services à nos clients</h3>

    <p>Nous utiliserons vos données à caractère personnel pour vous fournir ou pour fournir à nos
    clients les services demandés. À cet égard, nous pouvons également utiliser vos données à
    caractère personnel dans le cadre de correspondances liées aux services. Ces correspondances
    peuvent être entretenues avec vous, notre client, d’autres membres du Réseau Stockoma, nos
    prestataires de services ou des autorités compétentes. Nous pouvons également utiliser vos
    données à caractère personnel dans le cadre de contrôles de due diligence liés aux services.
    Dans la mesure où nous fournissons une vaste gamme de services à nos clients, la manière
    dont nous utilisons les données à caractère personnel dans le cadre de nos services varie
    également. Nous pouvons, par exemple, utiliser des données à caractère personnel :</p>

    <ul>
      <li>concernant un client, pour l’aider à remplir sa déclaration d’impôt, concernant les
      collaborateurs d’un client, afin de le contacter pendant l’exécution de nos services et
      pour le maintenir informé de l’avancement du projet ou pour lui communiquer des
      produits livrables.</li>
    </ul>

    <h3>Utilisation d’informations à caractère personnel pour d’autres activités entrant dans le cadre du
    fonctionnement de notre entreprise</h3>

    <p>Nous pouvons également utiliser vos données à caractère personnel pour les finalités
    suivantes ou en lien avec ce qui suit :</p>

    <ul>
      <li>des obligations légales et réglementaires applicables</li>
      <li>des demandes ou des communications en provenance d’autorités compétentes</li>
      <li>des finalités administratives</li>
      <li>des finalités de comptabilité financière, de facturation et d’analyse de risque</li>
      <li>des finalités de relation avec la clientèle, notamment : (i) vous transmettre des
          documents de leadership intellectuel ou des informations sur nos produits et services
          qui, selon nous, pourraient vous intéresser ; (ii) vous contacter afin d’obtenir vos
          commentaires sur nos services ; et (iii) vous contacter dans le cadre d’études de
          marché ou autres recherches. Dans tous les cas susmentionnés, nous vous donnons la
          possibilité de refuser nos offres et nos demandes à tout moment. Le cas échéant, dans
          des cas spécifiques, par exemple, si vous n’êtes pas encore un client, nous nous
          assurerons d’obtenir votre consentement préalable avant de vous envoyer des
          documents de communication ou d’autres demandes marketing (voir également à la
          suite, « Les bases juridiques que nous utilisons pour traiter les informations à caractère
          personnel ») ;</li>

      <li>des finalités de recrutement et de développement commercial (par exemple, des
          témoignages de collaborateurs d’un client peuvent être utilisés pour des documents de
          recrutement et de développement commercial avec la permission de ces
          collaborateurs)</li>
      <li>des services que nous recevons de nos conseillers professionnels, tels que des avocats,
          des comptables et des consultants</li>
      <li>protéger nos droits et ceux de nos clients.</li>
    </ul>

    <h3>Utilisation d’informations à caractère personnel collectées via notre Site Internet</h3>

    <p>En plus des finalités liées au fonctionnement de notre entreprise mentionnées ci-dessus, nous
    pouvons également utiliser vos données à caractère personnel collectées via notre Site Internet
    :</p>

    <ul>
      <li>pour gérer et améliorer notre Site Internet</li>
      <li>pour adapter le contenu de notre Site Internet, afin de vous fournir une expérience plus
          personnalisée, et attirer votre attention sur des informations concernant nos produits et
          services qui peuvent vous intéresser</li>
      <li>pour gérer et répondre à des demandes que vous soumettez par l’intermédiaire de notre
          Site Internet.</li>
    </ul>

    <h2>Les bases juridiques que nous utilisons pour traiter les informations à caractère
    personnel</h2>

    <p>Nous sommes légalement tenus de présenter dans cette déclaration de confidentialité les bases
    juridiques sur lesquelles nous nous appuyons pour traiter vos données à caractère personnel.</p>

    <p>Nous utilisons donc vos données à caractère personnel pour les finalités soulignées ci-dessus :
    (a) en raison des obligations légales et réglementaires auxquelles nous sommes soumis,
    notamment tenir des registres à des fins réglementaires ou fiscales ou fournir des informations
    à un organisme public ou (b) car ces informations sont nécessaires pour vous fournir nos
    services ou pour fournir nos services à nos clients. (c) en raison de nos intérêts légitimes aux
    fins de la livraison effective des services que nous vous fournissons ou que nous fournissons à
    nos clients ; (d) en raison de nos intérêts légitimes dans le fonctionnement effectif et légal de
    notre entreprise, du moment que ces intérêts ne soient pas supplantés par les vôtres ;</p>

    <p>Lorsque nous traiterons des données à caractère personnel à votre sujet pour l’une des
    finalités mentionnées ci-dessus nous le ferons pour les raisons suivantes : (i) vous nous avez
    donné votre consentement explicite pour traiter ces données ; (ii) nous sommes tenus par la loi
    de traiter ces données afin de nous assurer de remplir nos obligations de « connaissance du
    client » ou de « lutte contre le blanchiment d’argent » (ou d’autres obligations légales qui
    nous sont imposées) ; (iii) le traitement est nécessaire pour nous acquitter de nos obligations
    en vertu des lois relatives à l’emploi, à la sécurité sociale ou à la protection sociale ; (iv) le
    traitement est nécessaire à la constatation, à l’exercice ou à la défense de droits en justice ; ou
    v) vous avez rendu ces données publiques.</p>

    <p>Lorsque nous sommes tenus par la loi d’obtenir votre consentement explicite pour vous
    fournir certains documents de marketing, nous ne vous ferons parvenir ces documents que si
    nous avons obtenu votre consentement.</p>

    <h2>À qui communiquons-nous vos informations à caractère personnel ?</h2>

    <p>Pour l’une ou plusieurs des finalités soulignées dans la section « Comment utilisons-nous les
    informations à votre sujet ? » ci-dessus, nous pouvons communiquer des renseignements à
    votre sujet : à des tiers nous fournissant des services, aux autorités compétentes (y compris
    des tribunaux et des autorités de réglementation dont nous relevons), à votre employeur et/ou
    à ses conseillers, à vos conseillers, aux organismes qui nous aident à réduire les incidences de
    la fraude ainsi qu’à d’autres tiers ayant raisonnablement besoin d’accéder aux données à
    caractère personnel à votre sujet pour l’une ou plusieurs des finalités soulignées dans la
    section « Comment utilisons-nous les informations à votre sujet ? » ci-dessus.</p>

    <p>Notre Site Internet héberge divers blogs, forums, wikis et autres applications ou services de
    médias sociaux qui vous permettent de partager du contenu avec d’autres utilisateurs
    (collectivement, les « Applications de médias sociaux »). Il est important de noter que toutes
    les informations à caractère personnel que vous divulguez sur ces Applications de médias
    sociaux peuvent être lues, collectées et utilisées par d’autres utilisateurs de ces applications.
    Sachant que nous n’avons qu’un contrôle limité voire aucun contrôle sur ces utilisateurs, nous
    ne pouvons garantir que les informations que vous divulguez sur des Applications de médias
    sociaux seront traitées conformément à cette déclaration de confidentialité.</p>

    <p>Notez bien que certains des destinataires de vos données à caractère personnel mentionnés ci-
    dessus peuvent résider dans un pays non membre de l’Union européenne dont les lois ne
    fournissent peut-être pas le même degré de protection des données. Le cas échéant, nous nous
    assurerons qu’il existe des garanties adéquates pour protéger vos données à caractère
    personnel qui soient conformes à nos obligations légales. La garantie adéquate pourra
    consister à conclure un contrat de transfert des données avec le destinataire sur la base des
    clauses contractuelles standards approuvées par la Commission européenne pour les transferts
    de données à caractère personnel vers des pays tiers.</p>

    <p>Nous pouvons également avoir besoin de communiquer vos données à caractère personnel si
    nous y sommes tenus par une obligation légale, une réglementation ou dans le cadre d’actions
    en justice.</p>

    <p>Nous pouvons partager des informations à caractère non personnel, anonymes et agrégées
    avec des tiers pour plusieurs raisons, notamment pour l’analyse de données, la recherche, des
    propositions, du leadership intellectuel ainsi qu’à des fins promotionnelles.</p>


    <h2>Protection de vos informations à caractère personnel</h2>

    <p>Nous avons recours à un large éventail de mesures physiques, électroniques et managériales
    pour nous assurer que vos données à caractère personnel sont protégées, exactes et tenues à
    jour. Ces mesures comprennent :</p>

    <ul>
      <li>l’éducation et la formation du personnel concerné pour s’assurer qu’il connaît nos
          obligations en matière de confidentialité lorsqu’il traite les données à caractère
          personnel</li>
      <li>des contrôles administratifs et techniques pour restreindre l’accès aux données à
          caractère personnel sur la base du principe de « besoin de connaître »</li>
      <li>des mesures de sécurité technologiques notamment des pare-feu et des logiciels de
          cryptage et des antivirus</li>
      <li>des mesures de sécurité physique, telles que des badges de sécurité pour accéder à nos
          locaux. </li>
    </ul>

    <p>Bien que nous prenions des mesures de sécurité appropriées lorsque nous recevons vos
    données à caractère personnel, la transmission de données sur Internet (y compris par e-mail)
    n&#39;est jamais complètement sécurisée. Nous nous efforçons de protéger les données à caractère
    personnel, mais nous ne pouvons garantir la sécurité des données qui nous sont transmises ou
    que nous transmettons.</p>

    <h2>Pendant combien de temps conservons-nous vos informations ?</h2>

    <p>Nous conserverons vos données à caractère personnel dans nos systèmes pour une durée
    correspondant à la plus longue des périodes suivantes : (i) aussi longtemps qu’il est nécessaire
    pour l’activité ou les services concernés ; (ii) toute période de conservation requise par la loi ;
    ou (iii) la fin de la période au cours de laquelle des litiges peuvent apparaître ou des enquêtes
    peuvent être ouvertes concernant nos services.</p>

    <h3>Vos droits</h3>

    <p>Vous avez différents droits liés à vos données à caractère personnel. Vous avez en particulier
    le droit :</p>

    <ul>
      <li>d’obtenir la confirmation que nous traitons vos données à caractère personnel et de
          demander une copie des données à caractère personnel que nous conservons à votre
          sujet</li>
      <li>de nous demander de mettre à jour les données à caractère personnel que nous
          conservons à votre sujet ou de corriger les données que vous pensez être incorrectes
          ou incomplètes</li>
      <li>de nous demander de supprimer les données à caractère personnel que nous
          conservons sur vous ou de restreindre la manière dont nous utilisons ces données</li>
      <li>de retirer votre consentement à notre traitement de vos données à caractère personnel
          (dans la mesure où ce traitement est basé sur un consentement)</li>
      <li>de recevoir une copie des données à caractère personnel vous concernant que vous
          nous avez fournies, dans un format structuré, couramment utilisé et lisible par machine
          et de les transmettre à une autre partie (dans la mesure où ce traitement est basé sur un
          consentement ou un contrat).</li>
      <li>de vous opposer au traitement de vos données à caractère personnel.</li>
    </ul>

    <p>Pour exercer vos droits ou si vous avez d’autres questions concernant notre utilisation de vos
    données à caractère personnel, envoyez un e-mail à <a href={'mailto:' + gdprEmail }>{ gdprEmail }</a> ou écrivez-nous à
    l’adresse ci-dessous :</p>

    <p><b>{ registeredOffice }</b></p>

    <p>Vous pouvez également utiliser ces coordonnées de contact si vous souhaitez nous soumettre
    une réclamation relative à une atteinte à votre vie privée.</p>

    <h2>Droit de réclamation</h2>

    <p>Si vous n’êtes pas satisfait de la manière dont nous avons traité vos données à caractère
    personnel ou pour toute demande ou question relative à votre vie privée que vous avez
    soulevée auprès de nous, vous avez droit de porter une réclamation auprès de l’autorité
    chargée de la protection des données:</p>
    <p>
      Data Protection Authority<br />
      Rue de la Presse 35<br />
      <a href="mailto:contact@apd-gba.be">contact@apd-gba.be</a><br />
      <a href="https://www.dataprotectionauthority.be/" target="_blank" rel="noopener noreferrer">www.dataprotectionauthority.be</a>
    </p>

    <h2>Modifications de cette déclaration de confidentialité</h2>

    <p>Nous pourrons modifier cette déclaration de confidentialité périodiquement.</p>

    <p>Pour vous informer des modifications que nous apporterons à cette déclaration de
    confidentialité, nous changerons la date de modification en haut de la page. La nouvelle
    déclaration de confidentialité modifiée entrera en vigueur à compter de cette date de
    modification. Nous vous invitons donc à consulter cette déclaration périodiquement pour être
    informé de la manière dont nous protégeons vos informations</p>
  </div>
);


PrivacyStatementFr.propTypes = {
  gdprEmail: PropTypes.string.isRequired,
  registeredOffice: PropTypes.string.isRequired,
};
