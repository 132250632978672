import React, { Component } from 'react';
import { PropTypes } from '../../../util.js';
import { connect } from 'react-redux';
import { setCategoryPageSize, setCategorySortBy } from '../../../actions/index.js';
import { SortBySelector, PageSizeSelector } from '../controls.js';
import { Icon, SubCategoriesTagCloud, CategoryCrumb } from '../../controls.js';


class CategoryPageToolbarComponent extends Component {
  static propTypes = {
    setCategorySortBy: PropTypes.func.isRequired,
    setCategoryPageSize: PropTypes.func.isRequired,
    currentValues: PropTypes.shape({
      pageSize: PropTypes.number.isRequired,
      sortBy: PropTypes.string.isRequired,
    }).isRequired,
  }

  render() {
    const { currentValues } = this.props;
    return (
      <div>
        <form className="form-inline">
          <div className="category-toolbar row">
            <div className="col-sm-6">
              <SortBySelector
                onChange={(newSortBy: string) => this.props.setCategorySortBy(newSortBy)}
                value={currentValues.sortBy}
                style={{ marginRight: 12 }}
              />
            </div>
            <div className="col-sm-6">
              <PageSizeSelector
                onChange={(newSize: number) => this.props.setCategoryPageSize(newSize)}
                value={currentValues.pageSize}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const toolbarActions = { setCategorySortBy, setCategoryPageSize };
const toolbarStateMapper = state => ({ currentValues: state.app.category });
export const CategoryPageToolbar = connect(toolbarStateMapper, toolbarActions)(CategoryPageToolbarComponent);



class CollapsableCategoryPageToolbarComponent extends Component {
  static propTypes = {
    t: PropTypes.any.isRequired,
    category: PropTypes.CategoryModel.isRequired,
  }

  constructor() {
    super();
    this.state = { open: false };
  }

  render() {
    const { t, category } = this.props;
    return [
      <div
        key="filters-btn"
        className="filters-btn"
        onClick={() => this.setState({ open: !this.state.open })}
        title={t('category.openSubcatFilters')}
      >
        <Icon fa={`fa-angle-double-${this.state.open ? 'up' : 'down'}`} />
        <span>{t('search.search')}</span>
      </div>
      ,
      <CategoryCrumb key="crumb" category={category} />
      ,
      <div key="clearfix" className="clearfix" />
      ,
      <div key="filters">
        {this.state.open ? (
          <div className="filters-pane">

            <SubCategoriesTagCloud category={category} variableFontSize={false} />
            <hr style={{ width: '50%' }} />
            <CategoryPageToolbar />
          </div>
        ) : null}
      </div>
    ];
  }
}

const mapStateToProps = state => ({ t: state.t });
export const CollapsableCategoryPageToolbar = connect(mapStateToProps)(CollapsableCategoryPageToolbarComponent);
