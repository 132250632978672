import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from '../../../util';
import {SocialMediaIcons} from '../../controls/SocialMediaIcons';
import {FooterFacebookIcon} from './FooterFacebookIcon';

class ConnectWithUsComponent extends Component {
  static propTypes = {
    t: PropTypes.any.isRequired,
    className: PropTypes.string,
  }

  render() {
    const {t, className} = this.props;

    const mediaStyle = {
      marginTop: 7,
      display: 'inline',
    };

    return (
      <div className={className}>
        <div style={{marginLeft: -20}}>
          <strong className="footer-title">{t('footer.connect')}</strong>
        </div>
        <div className="footer-stay-connected">
          <SocialMediaIcons style={mediaStyle} />
          <FooterFacebookIcon />
        </div>
      </div>
    );
  }
}

export const Connect = connect(state => ({t: state.t}))(ConnectWithUsComponent);
